/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import filteroptions from "./filteroptions";
import { useDispatch } from "react-redux";
import "aos/dist/aos.css";
import { FaBookmark } from "react-icons/fa";
import { BsDatabaseFill } from "react-icons/bs";
import toast, { Toaster } from "react-hot-toast";
import {
  RequestedCreator,
  GetRequestedList,
} from "../../../actions/BrandActions";
import { useNavigate } from "react-router-dom";

const Talents = ({ creator, isBrand, brandData, addRequest }) => {
  //console.log(creator);
  // console.log(brandData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedFilter, setSelectedFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [requestedArray, setRequestedArray] = useState([]);
  const [requestData, setRequestData] = useState({
    brandID: "",
    creatorID: "",
    creatorEmail: "",
    creatorName: "",
    creatorImg: "",
    creatorNiche: [],
    createdAt: "",
  });

 
  const handleCardClick = async (profile) => {
    // console.log(creatorID)

    if (isBrand) {
      const requestPayload = {
        brandID: brandData._id,
        creatorID: profile._id,
        creatorEmail: profile.email,
        creatorName: profile.displayname,
        creatorImg: profile.profile_image,
        creatorNiche: profile.niche,
        createdAt: Date.now(),
      };

      // Update the state with the new request data
      setRequestData(requestPayload);
      // console.log(requestPayload);
      try {
        const res = await dispatch(RequestedCreator(requestPayload));
        //console.log("data",res);
        // console.log(res.data);
        //console.log(requestPayload.brandID);
        const response = await dispatch(
          GetRequestedList(requestPayload.brandID)
        );
        /// console.log(response);
        //console.log("rese",response.data);

        addRequest(response.data);
      } catch (error) {
        console.log(error);
      }
      setRequestedArray((prev) => [...prev, requestPayload]);
    } else {
      toast.error("You are not a Brand", {
        duration: 3000,
      });
    }
    //console.log(requestedArray);
    //setRequest(!request)
    //setSelectedProfile(profile);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFilterChange = (e) => {
    setSelectedFilter(e.target.value);
  };

  const filteredProfiles =
    creator?.filter((profile) => {
      const matchesSearchQuery = (profile.displayname || "")
        .toLowerCase()
        .includes((searchQuery || "").toLowerCase()); // Added null checks

      const matchesFilter = selectedFilter
        ? (profile.niche || "").includes(selectedFilter) // Handle undefined niche
        : true;

      return matchesSearchQuery && matchesFilter;
    }) || [];

  // console.log(filteredProfiles);
  // console.log("creator",creator)

  const filterOptions = filteroptions;

  const handleProfileClick = (profile) => {
    //console.log(profile)
    navigate(`/influencer/${profile._id}`, { state: { profile } });
  };
  return (
    <>
      <>
        <Toaster />
        <div>
          {/* Heading and Search Bar */}
          <div className=" flex flex-col lg:flex-row justify-between items-start lg:items-center  py-6 px-4 space-y-4 lg:space-y-0">
            {/* Subheader Section */}
            <div
              className="w-full lg:w-auto"
              style={{ fontFamily: "Gilroy-Medium" }}
            >
              <h1 className="text-2xl font-semibold text-gray-800">
                Talent Page
              </h1>
             
            </div>

            {/* Search and Filter Section */}
            <div className="w-full lg:w-auto flex flex-col lg:flex-row items-start lg:items-center gap-4">
              {/* Search Input */}
              <div className="w-full lg:w-auto">
                <input
                  type="text"
                  placeholder="Search your Creator"
                  className="w-full lg:w-auto px-4 py-2 border border-gray-300 rounded-lg focus:outline-none text-sm"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
              {/* Filter Dropdown */}
              <div className="w-full lg:w-auto">
                <select
                  className="w-full lg:w-auto px-4 py-2 border border-gray-300 rounded-lg text-sm focus:outline-none"
                  value={selectedFilter}
                  onChange={handleFilterChange}
                >
                  {filterOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Profile Cards Section */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredProfiles?.map((profile, index) => (
              <div
                key={index}
                className="bg-white shadow-lg rounded-lg p-6 border border-gray-200 w-full max-w-xs mx-auto"
              >
                {/* Profile Image */}
                <div className="relative">
                  <div className="group relative">
                    {/* Profile Image */}
                    <img
                      className="w-24 h-24 mx-auto rounded-full hover:border-pink-600 hover:shadow-lg hover:border-4 transition-all duration-300"
                      src={profile?.profile_image}
                      alt="Profile"
                    />
                    {/* Hover Text */}
                    <div
                      className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity text-white text-sm font-semibold cursor-pointer"
                      onClick={() => handleProfileClick(profile)}
                    >
                      View Profile
                    </div>
                  </div>
                </div>

                {/* Profile Details */}
                <h2
                  className="text-center text-xl font-semibold mt-2 mb-1"
                  style={{ fontFamily: "Gilroy-Medium" }}
                >
                  {profile.displayname}
                </h2>
                <div
                  className="text-center text-sm text-gray-600"
                  style={{ fontFamily: "Gilroy-Medium" }}
                >
                  {profile?.niche?.join(", ")}
                </div>

                {/* Profile Stats */}
                <div className="flex justify-between bg-gray-600 bg-opacity-10 rounded p-3 mt-2 mb-3 text-center">
                  <div>
                    <p
                      className="text-sm text-black mb-0"
                      style={{ fontFamily: "Gilroy-Medium" }}
                    >
                      Videos
                    </p>
                    <p className="text-lg font-semibold text-black mb-0">
                      {profile.totalPost}
                    </p>
                  </div>
                  <div className="space-y-0.1">
                    <p
                      className="text-sm text-black mb-0"
                      style={{ fontFamily: "Gilroy-Medium" }}
                    >
                      Followers
                    </p>
                    <p className="text-lg font-semibold text-black mb-0">
                    {profile.followers >= 1000000
                    ? (profile.followers / 1000000).toFixed(1) + "m"
                    : profile.followers >= 1000
                    ? (profile.followers / 1000).toFixed(1) + "k"
                    : profile.followers}
                    </p>
                  </div>
                  <div className="space-y-0.1">
                    <p
                      className="text-black text-sm mb-0"
                      style={{ fontFamily: "Gilroy-Medium" }}
                    >
                      ER%
                    </p>
                    <p className="text-lg font-semibold text-black mb-0">
                      {profile.ER}
                    </p>
                  </div>
                </div>

                {/* Profile Actions */}
                <div className="flex items-center justify-between">
                  <button
                    className="w-full text-white px-2 py-2 rounded-lg hover:bg-pink-700 hover:text-black transition"
                    onClick={() => handleCardClick(profile)}
                    style={{
                      fontFamily: "Gilroy-Medium",
                      background: "#2A3547",
                    }}
                  >
                    Request Price
                  </button>
                  <div className="flex gap-4 ml-4">
                    <div className="text-gray-500 border-2 p-2 rounded hover:text-pink-600 cursor-pointer">
                      <FaBookmark size={20} />
                    </div>
                    <div className="text-gray-500 border-2 p-2 rounded hover:text-pink-600 cursor-pointer">
                      <BsDatabaseFill size={20} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    </>
  );
};

export default Talents;
