import React, { useEffect, useState } from "react";
import useConversation from "../statemanage/useConversation.js";
import axios from "axios";
import baseUrl from "../api/universalApi.js";
import { useSelector } from "react-redux";

const useGetMessage = (activeUser ) => {
  // console.log(brandId, Influencer)
 // console.log(activeUser)
 // console.log("user bhi aa gaya", )
  const [loading, setLoading] = useState(false);
  const { messages, setMessage, selectedConversation } = useConversation();
   const user = useSelector((state) => state.auth.authData?.user);
  //  console.log(user)
  const fetchMessages = async (activeUser,user) => {
    // console.log("Fetching messages for user:", activeUser);
    // console.log("user bhi aa gaya", user)
    if (activeUser && user && activeUser._id) {
      setLoading(true); // Start loading
      try {

        if(user?.userType==="Brand")
        {
          const res = await axios.post(
            `${baseUrl}/api/message/get/${activeUser._id}`, 
            {
              userId:user._id
            }
          );
          //console.log("Messages retrieved:", res.data);
          setMessage(res.data); // Set the messages in state
        }else{
          const res = await axios.post(
            `${baseUrl}/api/message/get/${user._id}`, 
            {
              userId:activeUser._id
            }
          );
          //console.log("Messages retrieved:", res.data);
          setMessage(res.data); // Set the messages in state
        }
       
      } catch (error) {
        console.error("Error in fetching messages:", error);
      } finally {
        setLoading(false); // End loading
      }
    } else {
      console.log("No active user or user ID provided");
    }
  };

  // Example: Call `fetchMessages` when needed
  useEffect(() => {
    fetchMessages(activeUser, user);
  }, [activeUser, user])

  return { loading, messages };
};

export default useGetMessage;