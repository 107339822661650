import React, { useState, useEffect } from "react";
import styles from "./ProfileInfo.module.css";
//import { useNavigate } from "react-router-dom";
import { getInsta } from "../../../actions/AuthActions";
import { useDispatch } from "react-redux";
//import { FaSyncAlt, FaInstagram } from "react-icons/fa";
//import toast from "react-hot-toast";
//import Joyride, { ACTIONS, EVENTS } from "react-joyride";
import DemographicsChart from "./DemoComp";
import guide from "../../../assets/guide.gif";

const ProfileInfo = ({ profileData, isBrand, handleUserUpdate }) => {
//console.log(isBrand)
  // console.log("profileData", profileData);
  const [run, setRun] = useState(false);
  //const date = new Date(profileData.createdAt);
  const [facebookUserAccessToken, setFacebookUserAccessToken] = useState("");
  const [instaid, setInstaId] = useState(null);
  const [connecting, setConnecting] = useState(false);
  // const [connected, SetConnected] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [unComplete, setUnComplete] = useState(false);
  //const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log("data", profileData)

  const id = profileData._id;
  // console.log(id)
  // Function to open the popup
  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  // Function to close the popup
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // const handleUserDelete = () => {
  //   try {
  //     dispatch(DeleteUser(id));
  //     navigate("/");
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    if (!profileData.instaId) {
      setUnComplete(true);
    }
  },[]);

  /* --------------------------------------------------------
   *              FACEBOOK LOGIN AND INITIAL SETUP
   * --------------------------------------------------------
   */

  // Check if access token is in localStorage when the component mounts
  useEffect(() => {
    const storedAccessToken = localStorage.getItem("FacebookUserAccessToken");
    const storedInstaId = localStorage.getItem("InstaId");

    // window.FB.getLoginStatus((response) => {
    //   if (response.status === "connected") {
    //     const accessToken = response.authResponse?.accessToken;
    //     setFacebookUserAccessToken(accessToken);
    //     localStorage.setItem("FacebookUserAccessToken", accessToken);
    //   }
    // });
    if (storedAccessToken) {
      setFacebookUserAccessToken(storedAccessToken);
    } else {
      window.FB.getLoginStatus((response) => {
        if (response.status === "connected") {
          const accessToken = response.authResponse?.accessToken;
          setFacebookUserAccessToken(accessToken);
          //toast.success("Connected to Instagram");
          // setShowPopup(false);
        }
      });
    }

    if (storedInstaId) {
      setInstaId(storedInstaId);
    }

    // Trigger the chain of function calls if the user is already logged in
  }, [facebookUserAccessToken]);

  const logInToFB = async () => {
    window.FB.login(
      (response) => {
        const accessToken = response.authResponse?.accessToken;
        //const grantedScopes = response.authResponse?.grantedScopes;

        // console.log("grantedScopes", grantedScopes);
        //  console.log("accessToken", accessToken);
        if (accessToken) {
          //setFacebookUserAccessToken(accessToken);
          localStorage.setItem("FacebookUserAccessToken", accessToken);
        }
        passToken(accessToken);
      },
      {
        scope:
          "pages_show_list,business_management,instagram_basic,instagram_manage_insights", // Add desired permissions here
      }
    );
  };

  const passToken = async (token) => {
    const userId = profileData._id;
    console.log("Token", token);

    try {
      const data = dispatch(getInsta({ token, userId }));
      data
        .then((result) => {
          setShowPopup(false);
          ///  toast.success("Connected to Instagram");
          // console.log(result);
          setConnecting(false);
        })
        .catch((error) => {
          console.error("Error extracting data:", error);
        });
    } catch (error) {
      console.error("Request failed:", error);
    }
  };

  const showPopupDiv = () => {
    setShowPopup(true);
  };

  const handleConnect = () => {
    setRun(true);
    setConnecting(true);
    logInToFB();
  };

  // const handleOpenInstagram = () => {
  //   const url = `https://instagram.com/${profileData.instaUsername}`;
  //   window.open(url, "_blank");
  // };


const handleSetProfile = ()=>{
  setShowPopup(true);
  setUnComplete(false);
}

  return (
    <>
      <div className={styles.descriptionBox}>
        <div className={styles.section}>
          <h4
            // Only assign onClick if not connected
            className={
              profileData.instaId ? styles.nonClickable : styles.clickable
            }
          >
            {profileData.instaId ? (
              <>
                <div className="d-flex flex-row py-2 px-2 bg-light rounded-3">
                  <div
                    className="d-flex flex-row align-items-center bg-pink-600 rounded-full px-2 py-1 pr-2"
                    style={{ cursor: "pointer" }}
                  >
                    <div>
                    <img
                      src={profileData.instaProfileImg}
                      alt="profile"
                      className={styles.creatorPic}
                    />
                    </div>
                    <div className="text-white ">
                    {profileData.instaUsername}
                    </div>
                    <span className={styles.tooltip}>
                      ID: {profileData.instaId}
                    </span>
                  </div>
                  {/* {<div className="btn" onClick={handleConnect}>
                  🔃
                </div>} */}
                </div>
              </>
            ) : (
              <>
                <div>
                  <div>
                    {isBrand ? (
                      <>{""}</>
                    ) : (
                      <>
                        <button
                          className="btn btn-primary px-2 py-1 rounded-2"
                          onClick={showPopupDiv}
                        >
                          Connect Instagram
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}

            {profileData.instaId ? (
              <>
                <>
                  <div className="d-flex flex-row py-2 px-2 bg-light rounded-3">
                    <p className="me-3">
                      <span className="fw-bold">{profileData.followers}</span>{" "}
                      Followers
                    </p>
                    <p className="me-3">
                      <span className="fw-bold">{profileData.following}</span>{" "}
                      Following:
                    </p>
                    <p>
                      <span className="fw-bold">{profileData.ER}</span>% ER
                    </p>
                  </div>

                  {/* {  <div className="d-flex flex-column py-2 px-2 bg-light rounded-3">
                    <h4>Facebook Pages list</h4>
                    <div className="d-flex flex-column list" style={{ cursor: "pointer" }}>
                      {profileData.pagesList.map((page) => (
                        <>
                          <p key={page.id}>
                            {page.name}{" "}
                            <span className={styles.tooltip}>
                              ID: {page.id}
                            </span>
                          </p>
                          {" "}
                        </>
                      ))}
                    </div>
                  </div>} */}
                </>
              </>
            ) : (
              <></>
            )}
          </h4>

          <hr />

          {/* Popup Component */}
          {showPopup && (
            <div className={styles.popupOverlay} style={{fontFamily:"Gilroy-Medium"}}>
              <div className={styles.popupContent}>
                <button
                  className={styles.closeButton}
                  onClick={handleClosePopup}
                >
                  &times; {/* Cross symbol for closing */}
                </button>
                <h3>Instagram Connection</h3>
                <h4>Condition for connecting instagram to inggage</h4>

                <li>
                  Your instagram account should be linked to your valid facebook
                  page.
                </li>
                <li>Allow all permission in edit access.</li>
                <img
                  src={guide}
                  style={{ width: "450px", margin: "20px" }}
                  alt="guide"
                />
                <br />

                {connecting ? (
                  <button className="btn btn-primary">Connecting...</button>
                ) : (
                  <button
                    className="p-2 px-3 text-white rounded-full bg-[#ff007f]"
                    onClick={handleConnect}
                    style={{fontFamily:"Gilroy-Medium"}}
                  >
                    Connect Instagram
                  </button>
                )}
              </div>
            </div>
          )}

          {/* if Profile not connected Instagram */}
          {/* Popup Component */}
          {unComplete && !isBrand && (
            <div style={{fontFamily:"Gilroy-Medium"}}>
              <div className="fixed inset-0 h-100 w-100  flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="bg-white p-6 rounded-lg mx-2 shadow-lg overflow-auto max-h-full">
                  <h2 className="text-2xl font-bold mb-2">Just one last step  ......!</h2>
                  <p className="text-2xl mb-1">Please complete your profile by connecting Instagram to proceed. <br/>It will help you to make fast collaborations</p>
                  <button
                    className="mt-4 px-4 py-2 bg-[#ff007f]  text-white rounded-full hover:bg-blue-700 transition duration-300 ease-in-out"
                    onClick={() => handleSetProfile()}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={styles.section}>
          <h3>Description</h3>
          {profileData.bio ? (
            <p>{profileData.bio}</p>
          ) : (
            <p
              style={{ cursor: "pointer", color: "blue" }}
              onClick={handleUserUpdate}
            >
              Update Profile
            </p>
          )}

          <p className={styles.highlightedText}>
            I’m also a Top Pick Influencer
          </p>
        </div>
        {profileData.instaId && <DemographicsChart profileData={profileData} />}

        <hr />
      </div>
    </>
  );
};

export default ProfileInfo;
