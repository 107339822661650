import lyzr from "../../../assets/brands/lyzr.png";
//import ceat from "../../../assets/brands/ceat.png";
import Arthisha from "../../../assets/creators/Arthisha.jpg";
import HarshaReddy from "../../../assets/creators/Harsha.jpg";
import Yashwanth from "../../../assets/creators/yashwanth.jpg";

const testimonialsData = [
  {
    name: "Lyzr",
    brand: "",
    image: lyzr,
    text: "I had an excellent experience with Inggage! Their CPV models made influencer marketing highly cost-effective, delivering exactly the results I was aiming for. It's straightforward, efficient, and absolutely worth it. Highly recommend!",
    rating: 5.0,
    type: "brand", // New field
  },
  {
    name: "AccioJob",
    brand: "",
    image:
      "https://storage.tally.so/f019467a-bee7-4dc6-b425-5407b3b47c2b/acciojob-logo.jpeg",
    text: "Had a very positive experience with inggage! Their CPV models made influencer marketing super cost-effective, and the results were exactly what I was hoping for. Simple, efficient, and totally worth it! Would recommend",
    rating: 4.8,
    type: "brand",
  },
  {
    name: "Purplle",
    brand: "",
    image:
      "https://media4.ppl-media.com/mediafiles/ecomm/misc/ppl-icon-1024x1024.jpg",
    text: "My experience with Inggage has been fantastic! Their CPV models made influencer marketing incredibly affordable and delivered the exact results I wanted. The process is simple, efficient, and truly worth it. I highly recommend them!",
    rating: 4.9,
    type: "brand",
  },
  {
    name: "Arthisha",
    brand: "",
    image: Arthisha,
    text: "As a tech and AI influencer, Inggage is the perfect platform for me. I've had an excellent experience, and the transparency of the dashboard makes everything seamless.",
    rating: 5.0,
    type: "creator",
  },
  {
    name: "Harsha Reddy",
    brand: "",
    image: HarshaReddy,
    text: "As a tech and AI influencer, Inggage has been an ideal platform for me. My experience has been outstanding, and the dashboard's transparency ensures everything runs smoothly.",
    rating: 4.7,
    type: "creator",
  },
  {
    name: "Yashwanth",
    brand: "",
    image: Yashwanth,
    text: "Inggage is the perfect platform for me as a tech and AI influencer. My experience has been exceptional, and the transparent dashboard makes everything effortless and efficient.",
    rating: 5.0,
    type: "creator",
  },
];

export default testimonialsData;
