import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";

const ReelSubmit = () => {
  const [formData, setFormData] = useState({
    name: "",
    instagramId: "",
    reellink: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        "http://localhost:4000/campaign/reelSubmit",
        formData
      );

      if (response.status === 200) {
        toast.success("Data submitted successfully!");

        // setFormData({
        //   name: "",
        //   instagramId: "",
        //   reellink: "",
        // });
      } else if (response.status === 401) {
        toast.error("reel already exist");
      } else {
        toast.error("reel already exist!");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("reel already exist!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6 col-sm-12">
          <h2 className="text-center mb-4">Submit Reel Details</h2>
          <form
            onSubmit={handleSubmit}
            className="p-4 border rounded shadow-sm"
          >
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="form-control"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="instagramId" className="form-label">
                Instagram ID
              </label>
              <input
                type="text"
                id="instagramId"
                name="instagramId"
                className="form-control"
                placeholder="Enter Instagram ID"
                value={formData.instagramId}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="reelLink" className="form-label">
                Reel Link
              </label>
              <input
                type="url"
                id="reelLink"
                name="reellink"
                className="form-control"
                placeholder="Enter Reel Link"
                value={formData.reelLink}
                onChange={handleChange}
                required
              />
            </div>

            <div className="d-grid">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ReelSubmit;
