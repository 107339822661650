import React from "react";

const CampaignColl = ({ campaignData1 }) => {
  const campaigns = campaignData1 ?? [];

  return (
    <div className="p-6">
      <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {campaigns.length === 0 ? (
          // Fallback UI when no campaign data is available
          <p className="text-center text-gray-500 text-lg">No campaigns available</p>
        ) : (
          // Render campaign cards
          campaigns.map((campaign, index) => (
            <div
              key={index}
              className="rounded-lg shadow-lg bg-white overflow-hidden hover:shadow-xl transition-shadow duration-200"
            >
              <div>
                <img
                  src={campaign.campaignLogo || "https://via.placeholder.com/150"}
                  alt="Campaign Thumbnail"
                  className="w-full h-48 object-cover"
                />
              </div>
              <div className="p-3 flex flex-row  justify-between items-center">
                <p className="font-bold text-gray-800 mb-0">
                  {campaign.campaignName}
                </p>
                <p className="text-sm mb-0 bg-black  text-white px-1 py-0.5 rounded">{campaign.platform}</p>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default CampaignColl;
