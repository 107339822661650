import React, { useState } from "react";
//import { useSelector } from "react-redux";

// Import components for the right panel
import Home from "./Home/Home";
import Analytics from "./Analytics/Analytics";
import Creator from "./Creator/Creator";
import Chat from "./Chat/Chat";
import Requested from "./Requested/Requested";
import Talent from "./Talent/Talent";
import Settings from "./Settings/Settings";
import { BsChatSquareDots } from "react-icons/bs";
import { PiUsersThreeBold } from "react-icons/pi";
import { FiSettings } from "react-icons/fi";
import { TbDeviceAnalytics } from "react-icons/tb";
import { FiHome } from "react-icons/fi";
import { SiSpringCreators } from "react-icons/si";

const BrandSide = ({
  creator,
  isBrand,
  brandData,
  addRequest,
  requestedCreator,
  influencerData,
  campaignData,
  campaignId,
}) => {
  // State to manage the selected panel
  const [activePanel, setActivePanel] = useState("Home");
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Mobile menu toggle

  // Handlers for button clicks to change active panel
  const handlePanelClick = (panel) => {
    setActivePanel(panel);
    setIsMenuOpen(false); // Close menu on mobile when an option is selected
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      {/* Mobile Menu Toggle Button */}
      <button
        className="md:hidden p-2 bg-[#ff006b] text-white rounded mb-2 ml-2"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        Menu
      </button>

      {/* Left Navbar */}
      <div
        className={`${
          isMenuOpen ? "block" : "hidden"
        } md:block w-full md:w-1/5 bg-white mx-2 h-auto md:h-screen overflow-y-auto`}
        style={{ fontFamily: "Gilroy-Medium" }}
      >
        <nav className="mt-6 mx-2">
          <ul>
            <div className="my-2">DASHBOARD</div>
            <li
              onClick={() => handlePanelClick("Home")}
              className={`p-2 flex items-center cursor-pointer ${
                activePanel === "Home"
                  ? "bg-[#ff006b] text-white rounded"
                  : "hover:bg-gray-200 rounded"
              }`}
            >
              <FiHome className="mr-2" />
              <span className="material-icons mr-3">Home</span>
            </li>
            <li
              onClick={() => handlePanelClick("Analytics")}
              className={`p-2 flex items-center cursor-pointer ${
                activePanel === "Analytics"
                  ? "bg-[#ff006b] text-white rounded"
                  : "hover:bg-gray-200 rounded"
              }`}
            >
              <TbDeviceAnalytics className="mr-2" />
              <span className="material-icons mr-3"> Analytics</span>{" "}
            </li>
            <li
              onClick={() => handlePanelClick("Creator")}
              className={`p-2 flex items-center cursor-pointer ${
                activePanel === "Creator"
                  ? "bg-[#ff006b] text-white rounded"
                  : "hover:bg-gray-200 rounded"
              }`}
            >
              <SiSpringCreators className="mr-2" />
              <span className="material-icons mr-3">Creator</span>
            </li>
            <hr />
            <div className="my-2">APPLICATIONS</div>
            <li
              onClick={() => handlePanelClick("Chat")}
              className={`p-2 flex items-center cursor-pointer ${
                activePanel === "Chat"
                  ? "bg-[#ff006b] text-white rounded"
                  : "hover:bg-gray-200 rounded"
              }`}
            >
              <BsChatSquareDots className="mr-2" />

              <span className="material-icons mr-3"> Chat</span>
            </li>

            <li
              onClick={() => handlePanelClick("Talent")}
              className={`p-2 flex items-center cursor-pointer ${
                activePanel === "Talent"
                  ? "bg-[#ff006b] text-white rounded"
                  : "hover:bg-gray-200 rounded"
              }`}
            >
              <PiUsersThreeBold className="mr-2" />
              <span className="material-icons mr-3">Talent</span>
            </li>

            <li
              onClick={() => handlePanelClick("Requested")}
              className={`p-2 flex items-center cursor-pointer ${
                activePanel === "Requested"
                  ? "bg-[#ff006b] text-white rounded"
                  : "hover:bg-gray-200 rounded"
              }`}
            >
              <PiUsersThreeBold className="mr-2" />
              <span className="material-icons mr-3">Requested</span>
            </li>

            <li
              onClick={() => handlePanelClick("Settings")}
              className={`p-2 flex items-center cursor-pointer ${
                activePanel === "Settings"
                  ? "bg-[#ff006b] text-white rounded"
                  : "hover:bg-gray-200 rounded"
              }`}
            >
              <FiSettings className="mr-2" />
              <span className="material-icons mr-3">Settings</span>
            </li>
          </ul>
        </nav>
      </div>

      {/* Right Panel */}
      <div className="flex-1 overflow-scroll bg-white p-2">
        {activePanel === "Home" && <Home user={brandData} />}
        {activePanel === "Analytics" && (
          <Analytics
            addRequest={addRequest}
            requestedCreator={requestedCreator}
            brandData={brandData}
            campaignData={campaignData}
            campaignId={campaignId}
          />
        )}
        {activePanel === "Creator" && <Creator />}
        {activePanel === "Chat" && <Chat />}
        {activePanel === "Requested" && (
          <Requested requestedCreator={requestedCreator} />
        )}
        {activePanel === "Talent" && (
          <Talent
            creator={creator}
            isBrand={isBrand}
            addRequest={addRequest}
            brandData={brandData}
          />
        )}
        {activePanel === "Settings" && <Settings />}
      </div>
    </div>
  );
};

export default BrandSide;
