/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./Header.module.css";
import axios from "axios";
import logo from "../../assets/images/Logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
//import toast from "react-hot-toast";
import { logout } from "../../actions/AuthActions";
import toast, { Toaster } from "react-hot-toast";
import { FaUserCircle } from "react-icons/fa";
import { GetRequestedList } from "../../actions/BrandActions.js";
import baseUrl from "../../api/universalApi.js";
import { saveCampaignData } from "../../actions/BrandActions.js";



const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNavVisible, setIsNavVisible] = useState(false);
  const isLogin = useSelector((state) => state.auth.isLogin);
  const authData = useSelector((state) => state.auth.authData);
  const [isBrand, setIsBrand] = useState(false);
  const [isInfluencer, setIsInfluencer] = useState(false);
  const [campaignData1, setCampaignData] = useState(null);

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        const response = await axios.get(
          `${baseUrl}/campaign/getCampaigns/${id}`
        );
        if (response.status === 200) {
          const data = response.data.data; // Axios automatically parses JSON
          setCampaignData(data); // Storing fetched data in state
          //console.log("Fetched campaign data:", data);
          dispatch(saveCampaignData(data));
        } else {
          console.error("Failed to fetch campaign data");
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    if (authData && authData.user) {
      const Brand = authData.user.userType === "Brand";

      if (!Brand) {
        setIsInfluencer(true);

        const id = authData.user._id;
        fetchData(id); // Call API if user is not a brand
      } else {
        setIsInfluencer(false);
      }

      setIsBrand(Brand);
    }
  }, [authData, dispatch]);

  //console.log(authData.userType)

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleProfile = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setIsNavVisible(!isNavVisible);
    if (authData) {
      if (isBrand) {
        const id = authData.user._id;
        // console.log(id);
        navigate(`/Brandside/profile/${id}`);
      } else {
        const id = authData.user._id;
        navigate(`/influencer/${id}`);
      }
    }
  };

  const handleClick = () => {
    setIsNavVisible(!isNavVisible);
    navigate("/Signup");
  };

  const handleLoginClick = () => {
    navigate("/Login");
  };

  const showCampaign = () => {
    const id = authData?.user?._id;
    navigate(`/Brand/analytics/${id}`);
  };

  const handleLogout = () => {
    setIsNavVisible(!isNavVisible);
    try {
      dispatch(logout(navigate));
      toast.success("Logged out!!", {
        duration: 4000,
      });
      //window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Toaster />
      <header className={styles.headerContainer}>
        <div className={styles.headerContent}>
          <div>
            <a href="/">
              <img src={logo} alt="Logo" className={styles.logo} />
            </a>
          </div>
          <div className={styles.menuIcon} onClick={toggleNav}>
            <input
              type="checkbox"
              role="button"
              aria-label="Display the menu"
              className={styles.menu}
              checked={isNavVisible}
              onChange={toggleNav}
            />
          </div>
          <nav
            className={`${styles.navigation} ${
              isNavVisible ? styles.showNav : ""
            }`}
          >
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? styles.active : styles.navLink
              }
              onClick={toggleNav}
            >
              Home
            </NavLink>
            <NavLink
              to="/creators"
              className={({ isActive }) =>
                isActive ? styles.active : styles.navLink
              }
              onClick={toggleNav}
            >
              Creator
            </NavLink>
            <NavLink
              to="/brands"
              className={({ isActive }) =>
                isActive ? styles.active : styles.navLink
              }
              onClick={toggleNav}
            >
              Brand
            </NavLink>
            {isBrand && (
              <>
                <NavLink
                  to="/talent"
                  className={({ isActive }) =>
                    isActive ? styles.active : styles.navLink
                  }
                  onClick={toggleNav}
                >
                  Talent
                </NavLink>
                <NavLink to={`/Brand/analytics/${authData?.user?._id}`}
                  className={({ isActive }) =>
                    isActive ? styles.active : styles.navLink
                  }>
                  Campaign
                </NavLink>
              </>
            )}

            {isInfluencer && (
              <NavLink
                to="/campaigndashboard"
                className={({ isActive }) =>
                  isActive ? styles.active : styles.navLink
                }
                onClick={toggleNav}
              >
                Campaign
              </NavLink>
            )}

            {/* If not Logged In */}
            {isLogin ? (
              <div
                className={styles.dropdownContainer}
                onMouseEnter={() => setIsDropdownOpen(true)}
                onMouseLeave={() => setIsDropdownOpen(false)}
              >
                <FaUserCircle
                  onClick={toggleDropdown}
                  className={styles.userProfileButton}
                />
                {isDropdownOpen && (
                  <div className={styles.dropdownMenu}>
                    <button
                      onClick={handleProfile}
                      className={styles.dropdownItem}
                    >
                      Profile
                    </button>
                    <button
                      onClick={handleLogout}
                      className={styles.dropdownItem}
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div>
                  <button
                    className={styles.GetinTouch}
                    onClick={() => {
                      navigate("/Contact");
                    }}
                  >
                    GET IN TOUCH
                  </button>
                </div>

                <div>
                  <button
                    onClick={handleLoginClick}
                    className={styles.signFormButton}
                  >
                    LOGIN
                  </button>
                  <button
                    onClick={handleClick}
                    className={styles.signFormButton}
                  >
                    SIGNUP
                  </button>
                </div>
              </>
            )}
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
