import React from "react";
import styles from "./Creator.module.css";

import DashboardView from "../../assets/brands/DashboardView.png";
import Newsletter from "../LandingPage/Newsletter/Newsletter";
import ceat from "../../assets/brands/ceat.png";
import ajio from "../../assets/brands/ajio.png";
import dbs from "../../assets/brands/dbs.png";
import tata from "../../assets/brands/tata.png";
import magicbrush from "../../assets/brands/magicbrush.png";
import gym from "../../assets/brands/gym.png";
import studio from "../../assets/brands/studio.png";
import metrix1 from "../../assets/metrix1.png";
import metrix2 from "../../assets/metrix2.png";
import Tableview from "../../assets/brands/Tableview.png";
import Scroller from "./Scroller/Scroller";
import Lfeedback from "./Lfeedback/Lfeedback";

const Brand = () => {

  const brands = "Brands";
    const Para =
      "Build authentic relationships with brands on Inggage. Our direct chat feature makes it easy to connect, collaborate, and negotiate terms. Making every collaboration a right fit for you.";
  return (
    <>
      <div className={styles.CreatorContainer}>
        <div className={styles.CreatorHeroPage}>
          <h1>
           
          Create more, Earn more.
          </h1>
          <p>
            Connect with top brands and expand your reach. Increase your brand
            deals and earnings today. Join our community of talented creators
            and experience fair pay, flexible options, and
            endless opportunities.
          </p>
          <div
            className="flex flex-col md:flex-row items-center justify-center gap-4"
            style={{ fontFamily: "Gilroy-Medium" }}
          >
          <button 
          className="px-4 py-2 text-white bg-primaryButton rounded-lg shadow-md hover:bg-pink-600 transition"
          >Start for Free</button>
          </div>
          <div className={styles.GetInTouch}>
            <p>
              Want to talk or get a live demo?{" "}
              <a href="#contact"> Get in touch →</a>
            </p>
          </div>
        </div>
        {/**Brand Icons section */}
        <div className={styles.BrandIcons}>
          <div className={styles["brands-logos"]}>
            <div className={styles["brand-logo"]}>
              <img src={ceat} alt="Logo 1" />
            </div>
            <div className={styles["brand-logo"]}>
              <img src={ajio} alt="Logo 2" />
            </div>
            <div className={styles["brand-logo"]}>
              <img src={dbs} alt="Logo 3" />
            </div>
            <div className={styles["brand-logo"]}>
              <img src={magicbrush} alt="Logo 4" />
            </div>
            <div className={styles["brand-logo"]}>
              <img src={tata} alt="Logo 5" />
            </div>
            <div className={styles["brand-logo"]}>
              <img src={gym} alt="Logo 6" />
            </div>
            <div className={styles["brand-logo"]}>
              <img src={studio} alt="Logo 7" />
            </div>
          </div>
        </div>

        {/**Table View  */}
        <div className={styles.TableView}>
          <div>
            <p>Best time here</p>
            <h1>
              View how our other creators are
              <br /> performing
            </h1>
          </div>
          <div className={styles.tableimg}>
            <img src={Tableview} alt="Tableview" />
          </div>
        </div>

        {/**Two Boxes of Metrixes */}
        <div className={styles.MetrixContainer}>
          <div>
            <h1>View All the Metrices</h1>
          </div>
          <div className={styles.MetrixBox}>
            <div className={styles.metricCard}>
              <h3>Projects</h3>
              <p>Organize, follow, and archive.</p>
              <div className={styles.metricImage}>
                <img src={metrix1} alt="Project metrics graph" />
              </div>
            </div>

            <div className={styles.metricCard}>
              <h3>Member profiles</h3>
              <p>Automatic portfolios of your work.</p>
              <div className={styles.metricImage}>
                <img src={metrix2} alt="Member profiles graph" />
              </div>
            </div>
          </div>
        </div>

          {/**Select and chat with the Influencer directly */}
        <Lfeedback brands={brands} Para={Para}/>

        {/** Dashboard View left right */}
        <div className={styles.DashBoardView}>
          <div className={styles.LeftPanelDash}>
            <button className={styles.shareButton}>Share</button>
            <h1 className={styles.mainHeading}>
              Track your Campaign <br /> with our Interactive Dashboard
            </h1>
            <p className={styles.description}>
              As a creator on Inggage, you'll have access to a powerful
              dashboard that provides real-time insights into your campaign
              performance. Easily monitor key metrics, analyze your audience
              engagement, and make data-driven decisions to optimize your
              content strategy.
            </p>
            
            <div className={styles.howItWorks}>
            
              <span>&#9654;See how it works</span>
            </div>
          </div>

          <div className={styles.RightPanelDash}>
            <img src={DashboardView} alt="Dashboard View" />
          </div>
        </div>

        <div className={styles.Newsletter}>
          <div>
            <Newsletter />
          </div>
        </div>
      </div>
    </>
  );
};

export default Brand;
