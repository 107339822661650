import React, { useEffect, useState } from "react";
import Overview from "./Overview/Overview";
import Analytics from "./Analytics/Analytics";
import Reports from "./Reports/Reports";
import { useDispatch, useSelector } from "react-redux";
import { FaDownload } from "react-icons/fa";
import styles from "./CampaignDash.module.css";

const CampaignDashboard = () => {
  const [activeTab, setActiveTab] = useState("overview");
  const [creatorData, setCreatorData] = useState(null);
  const campaign = useSelector((state) => state.campaigns?.campaigns);
  const campaignData = campaign?.data;
  //console.log(campaignData)
  
  
  const authData = useSelector((state) => state.auth.authData);
  //console.log(authData);

 useEffect(() => {
  // Check if user ID exists in auth data
  if (authData.user?._id) {
   // console.log("Auth User ID:", authData.user._id);

    // Ensure campaignData is a valid array
    const foundCreator = (Array.isArray(campaignData) ? campaignData : [])
      .flatMap((campaign) => campaign.creators || []) // Default to an empty array for creators
      .find((creator) => creator._id === authData.user._id);

    // Log the found creator, or indicate not found
    if (foundCreator) {
     // console.log("Creator Found:", foundCreator);
      setCreatorData(foundCreator);
    } else {
      console.log("Creator Not Found!");
    }
  }
}, [authData.user._id, campaignData]);

  const renderContent = () => {
    switch (activeTab) {
      case "overview":
        return <Overview creatorData={creatorData}/>;
      case "analytics":
        return <Analytics />;
      case "reports":
        return <Reports />;
      default:
        return <Overview creatorData={creatorData} />;
    }
  };
  
  return (
    <div className={styles.dashboardContainer}>
      <header className={styles.dashboardHeader}>
        <h1>
          {campaignData && campaignData.length > 0
            ? `${campaignData[0].campaignName} - ${authData.user.displayname}`
            : "No campaign"}
        </h1>
        <div className={styles.dateDownload}>
          <div className={styles.dateRange}>
            <span>{campaignData && (
              <>
                {new Date(campaignData[0]?.startDate).toLocaleDateString()} - {new Date(campaignData[0]?.endDate).toLocaleDateString()}
              </>
            )}</span>
          </div>
          <button className={styles.downloadButton}><FaDownload/></button>
        </div>
      </header>
      <div className={styles.dashboardNav}>
        <div className={styles.buttonContainer}>
          <button
            className={`${styles.navItem} ${
              activeTab === "overview" ? styles.active : ""
            }`}
            onClick={() => setActiveTab("overview")}
          >
            Overview
          </button>
          <button
            className={`${styles.navItem} ${
              activeTab === "analytics" ? styles.active : ""
            }`}
            onClick={() => setActiveTab("analytics")}
          >
            Analytics
          </button>
          <button
            className={`${styles.navItem} ${
              activeTab === "reports" ? styles.active : ""
            }`}
            onClick={() => setActiveTab("reports")}
          >
            Reports
          </button>
        </div>
      </div>
      <div className={styles.contentContainer}>{renderContent()}</div>
    </div>
  );
};

export default CampaignDashboard;
