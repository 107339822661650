import * as AuthApi from "../api/AuthRequests";
import * as updataAPI from "../api/CreatorRequests";
import toast from "react-hot-toast";

// SignUp Influencer dispatch Actions
export const signUpInflu = (formData, navigate) => async (dispatch) => {
  dispatch({ type: "AUTH_START" });
  try {
    const { data } = await AuthApi.signUpInflu(formData);
    if(data.success){
      dispatch({ type: "AUTH_SUCCESS", data: data });
      navigate(`/influencer/${data?.user?._id}`, { replace: true });
     }
     else{
      toast(data.message)
     }
  } catch (error) {
    console.log(error);
    dispatch({ type: "AUTH_FAIL" });
  }
};
// Login Influencer with Google and Facebook dispatch Actions
export const logInWithGoogle = (email, navigate) => async (dispatch) => {
  dispatch({ type: "AUTH_START" });
  try {
    //console.log(email)
    const { data } = await AuthApi.logInWithGoogle({email});
   // console.log(data)
   if(data.success){
    dispatch({ type: "AUTH_SUCCESS", data: data });
    const id = data.user._id;
    navigate(`/influencer/${id}`, { replace: true });
    toast.success("Login Successful");
   }
   else{
    toast(data.message)
   }
   
  } catch (error) {
    console.log(error);
    dispatch({ type: "AUTH_FAIL" });
  }
};
// Login Influencer with Password dispatch Actions
export const logInWithPass = (formData, navigate) => async (dispatch) => {
  dispatch({ type: "AUTH_START" });
  try {
    const { data } = await AuthApi.logInWithPass(formData);
    //console.log(data);
    dispatch({ type: "AUTH_SUCCESS", data: data });
    const id = data.user._id;
    navigate(`/influencer/${id}`, { replace: true });
    toast.success("Login Successful");
  } catch (error) {
    const error1 = error.response.data.message 
    toast(error1)
    console.log("this is error",error1);
    dispatch({ type: "AUTH_FAIL" });
  }
 
};

export const signUpBrand = (formData, navigate) => async (dispatch) => {
  dispatch({ type: "AUTH_START" });
 // console.log(formData);
  try {
    const { data } = await AuthApi.signUpBrand(formData);
    dispatch({ type: "AUTH_SUCCESS", data: data });
    navigate("../", { replace: true });
  } catch (error) {
    console.log(error);
    dispatch({ type: "AUTH_FAIL" });
  }
};

export const BrandWithGoogle = (email, navigate) => async (dispatch) => {
  dispatch({ type: "AUTH_START" });
 // console.log(email)
  try {
   // console.log(email)
    const { data } = await AuthApi.brandLoginWithGoogle(email);
  //  console.log(data);
    dispatch({ type: "AUTH_SUCCESS", data: data });
    const id = data.user._id;
    navigate(`/Brandside/profile/${id}`, { replace: true });
    toast.success("Logged in successful!")

    // setTimeout(() => {
    //   window.location.reload();
    // }, 0); 
  } catch (error) {
    console.log(error.response.data.message);
    toast.error(error.response.data.message, { duration: 3000 });
    dispatch({ type: "AUTH_FAIL" });
  }
};

export const BrandWithPass = (formData, navigate) => async (dispatch) => {
  dispatch({ type: "AUTH_START" });
  try {
    const { data } = await AuthApi.brandWithPass(formData);
    //console.log(data);
    dispatch({ type: "AUTH_SUCCESS", data: data });
    const id = data.user._id;
    navigate(`/Brandside/profile/${id}`, { replace: true });
    toast.success("Logged in successful!")
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({ type: "AUTH_FAIL" });
  }
};

export const logout = (navigate) => async (dispatch) => {
  dispatch({ type: "LOG_OUT" });
  navigate("../login");
  localStorage.clear();
  setTimeout(() => {
    window.location.reload();
  }, 0);
};



export const DeleteUser = (id) => async (dispatch) => {
  try {
    // console.log("id",id)

    const { data } = await AuthApi.DeleteUser(id);
    //console.log(data)
    dispatch({ type: "DELETE_USER" });
  } catch (error) {
    console.log(error);
  }
};

export const UpdateUser = (id, formData) => async (dispatch) => {
  dispatch({ type: "AUTH_START" });
  try {
    const { data } = await updataAPI.updateUser(id, formData);
    console.log(data);
    dispatch({ type: "UPDATING_START" });
  } catch (error) {
    console.log(error);
  }
};

// Get in touch Route to send response from DB collection Contact
export const contactUs = (formData) => async (dispatch) => {
  try {
    
    const response = await AuthApi.ContactUs(formData);
    console.log(response.data)
    if(response.data.success){
      toast.success("Contacted successfully!")
    }
   
  } catch (error) {
    console.log(error);
    toast.error("Repeated email!")
  }
};


export const getInsta = (Data) => async (dispatch) => {
  dispatch({type: "UPDATING_START"})
  try{
      const {data} = await AuthApi.getInstaInfo(Data);
    ///  console.log("Insta ka data milne ke badd profile state update : ",data)
      dispatch({type: "UPDATING_SUCCESS", data: data})
      return data;
  }   
  catch(error){
      dispatch({type: "UPDATING_FAIL"})
  }
};



