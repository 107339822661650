import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const BrandProfile = ({ user }) => {
  const navigate = useNavigate();
  // const id = user?._id;
  const [id, setId] = useState(null);

  const campaign = useSelector((state) => state.campaigns?.campaigns?.data);
  const campaignData = campaign || [];

  const runningCampaigns = campaignData.filter(
    (campaign) => campaign.status === "running"
  );
  const completedCampaigns = campaignData.filter(
    (campaign) => campaign.status === "completed"
  );

  const authData = useSelector((state) => state.auth.authData);

  useEffect(() => {
    if (authData && authData.user) {
      const Brand = authData.user.userType === "Brand";

      if (!Brand) {
        setId(authData?.user._id);
      }
    }
  }, []);

  const [key, setKey] = useState("running");

  const handleDetails = () => {
    navigate(`/Brand/analytics/${user._id}`);
  };

  return (
    <div className="container mx-auto py-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Left Section - Brand Profile */}
        <div className="bg-white border-2 rounded-lg p-6 flex flex-col items-center text-center">
          <img
            className="w-24 h-24 object-cover rounded-full mb-4"
            src={user?.profile_image || "https://via.placeholder.com/150"}
            alt="Profile"
          />
          <h2 className="text-lg font-semibold">{user?.displayname}</h2>
          <p className="text-sm text-gray-600 mt-2">
            <strong>Industry:</strong> {user?.industry}
          </p>
          <p className="text-sm text-gray-600 mt-1">
            <strong>Email:</strong> {user?.email}
          </p>
        </div>

        {/* Right Section - Campaigns Tabs */}
        <div className="md:col-span-2">
          <h3 className="text-xl font-semibold mb-4">Campaigns</h3>
          <div className="flex border-b border-gray-200">
            <button
              className={`flex-1 py-2 text-center ${
                key === "running"
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : "text-gray-500"
              }`}
              onClick={() => setKey("running")}
            >
              Running
            </button>
            <button
              className={`flex-1 py-2 text-center ${
                key === "completed"
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : "text-gray-500"
              }`}
              onClick={() => setKey("completed")}
            >
              Completed
            </button>
          </div>

          {/* Campaigns */}
          <div className="mt-4">
            {key === "running" && (
              <div className="grid grid-cols-1 sm:grid-cols-5 md:grid-cols-3 gap-4">
                {runningCampaigns.length > 0 ? (
                  runningCampaigns.map((campaign) => (
                    <div
                      key={campaign._id}
                      className="bg-white border-2  rounded-lg overflow-hidden"
                    >
                      <img
                        className="w-full h-32 md:h-50   object-cover"
                        src={campaign.campaignLogo || ""}
                        alt="Campaign Thumbnail"
                      />

                      <div className="p-2">
                        <h4 className="font-medium mb-1 text-lg">
                          {campaign.campaignName}
                        </h4>
                        <button
                          className="text-blue-500 text-bold"
                          onClick={()=>handleDetails()}
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-500">No running campaigns.</p>
                )}
              </div>
            )}
            {key === "completed" && (
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {completedCampaigns.length > 0 ? (
                  completedCampaigns.map((campaign) => (
                    <div
                      key={campaign._id}
                      className="bg-white shadow-md rounded-lg overflow-hidden"
                    >
                      <img
                        className="w-full h-32 object-cover"
                        src={
                          campaign.campaignLogo ||
                          "https://via.placeholder.com/150"
                        }
                        alt="Campaign Thumbnail"
                      />
                      <div className="p-4">
                        <h4 className="font-medium text-lg">
                          {campaign.campaignName}
                        </h4>
                        <button
                          className="text-blue-500 mt-2 underline"
                          onClick={()=>handleDetails()}
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-500">No completed campaigns.</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandProfile;
