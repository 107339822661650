import React, { useEffect, useState } from "react";
import styles from "./Overview.module.css";
import CustomLineChart from "./LineChart";
import CampaignPerformance from "./CampaignPerformance";
// import { useSelector } from "react-redux";
import baseUrl from "../../../api/universalApi";

const Overview = ({ influencerData, campaignData1, creatorData }) => {
  // const [idx, setIdx] = useState(0);
  const [views, setViews] = useState({});
  const [creator, setCreator] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const [selectedCreator, setSelectedCreator] = useState(null);
  //console.log("creatorData",creatorData)
  //console.log("campaignData1",campaignData1)
  // Get campaign data from Redux store
  const campaignData =
    campaignData1 && campaignData1.length > 0 ? campaignData1[0] : {};

  // useEffect(() => {
  //   if (campaignData && campaignData[0]?.creators?.length > 0) {
  //     // Set the first creator as default
  //     const firstCreator = campaignData[0].creators[0];
  //     setSelectedCreator(firstCreator);
  //     handleCreator(firstCreator); // Fetch initial creator data
  //   }
  // }, [campaignData]);
  useEffect(() => {
    if (creatorData) {
      setSelectedCreator(creatorData);
      /// handleCreator(creatorData);
    }
  }, [creatorData]);

  const handleCreator = async (creator) => {
    setSelectedCreator(creator); // Update selected creator
    try {
      const response = await fetch(`${baseUrl}/campaign/campaignDataRefresh`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ influxCreatorName: creator.displayname }),
      });
      const data = await response.json();
      setGraphData(data.graphData);

      // Update creator metrics with the response data
      if (data.creatorMetrics) {
        setCreator({
          ...creator,
          earnedAmount: data.creatorMetrics.earnedAmount || 0,
          totalBudget: data.creatorMetrics.totalBudget || 0,
          ER: data.creatorMetrics.ER || 0,
          currentReelViews: data.creatorMetrics.currentReelViews || 0,
          avgViews: data.creatorMetrics.avgViews || 0,
          ACPV: data.creatorMetrics.ACPV || 0,
        });
      }
    } catch (error) {
      console.error("Error fetching campaign data:", error);
    }
  };

  const handleViews = (views, idx) => {
    // setIdx(idx);
    setViews(views);
  };

  // Format date to readable string
  // const formatDate = (dateString) => {
  //   return new Date(dateString).toLocaleDateString();
  // };

  // // Calculate campaign duration
  // const getCampaignDuration = () => {
  //   if (campaignData) {
  //     const start = new Date(campaignData.startDate);
  //     const end = new Date(campaignData.endDate);
  //     const days = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
  //     return `${days} days`;
  //   }
  //   return "N/A";
  // };

  // Format numbers with commas
  const formatNumber = (num) => {
    return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "0";
  };

  return (
    <>
      <div className={styles.overviewContainer}>
        <div className={styles.card}>
          <div className={styles.cardContent}>
            <p>Earned till now</p>
            <h3 className={styles.greenText}>
              ₹{formatNumber(creatorData?.earnedAmount || 0)}
            </h3>
            {/* <p className={styles.subText}>
              out of budget{" "}
              <span className={styles.redText}>
                ₹{formatNumber(selectedCreator?.totalBudget || 0)}
              </span>
            </p> */}
          </div>
          <div className={styles.icon}>💵</div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardContent}>
            <p>Average Engagement Rate</p>
            <h3 className={styles.blackText}>{selectedCreator?.ER || 0}%</h3>
            <p className={styles.subText}>
              Platform: {campaignData?.platform || "Instagram"}
            </p>
          </div>
          <div className={styles.icon}>🥰</div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardContent}>
            <p>Total Video Views</p>
            <h3 className={styles.blackText}>
              {formatNumber(selectedCreator?.currentReelViews || 0)}
            </h3>
            <p className={styles.subText}>
              average views {formatNumber(selectedCreator?.avgViews || 0)}
            </p>
          </div>
          <div className={styles.icon}>👀</div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardContent}>
            <p>Average CPV</p>
            <h3 className={styles.blackText}>
              ₹{(selectedCreator?.ACPV || 0).toFixed(2)}
            </h3>
          </div>
          <div className={styles.icon}>🔂</div>
        </div>
      </div>

      <div className={styles.ChartNPerform}>
        <div className={styles.chartContainer}>
          <CustomLineChart
            creator={creator}
            handleViews={handleViews}
            graphData={graphData}
          />
        </div>
        <div className={styles.performanceContainer}>
          <CampaignPerformance
            creatorData={creatorData}
           
            handleCreator={handleCreator}
          
          />
        </div>
      </div>
    </>
  );
};

export default Overview;
