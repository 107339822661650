/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Graph from "../../../assets/Graph.png";

const Campaign = () => {
  return (
    <div className="flex flex-col m-10 my-10  md:flex-row  justify-center items-center  md:m-6 font-bold animate-[appear_1s_linear]">
      <div
        className="p-4 mx-4 w-full md:w-1/2"
        style={{ fontFamily: "Gilroy-Medium" }}
      >
        <h2 className="text-3xl md:text-4xl mb-1 font-medium ">
          AccioJob Campaign - 2025
        </h2>
        <p className="text-sm md:text-base text-gray-800 leading-relaxed mb-2">
          AccioJob is an ed-tech startup run by IIT Delhi Alumni and backed by Y
          Combinator & Mynavi. We provide the ultimate learning platform for
          students to start their careers in Software Development, Data Science,
          & Cybersecurity. Our courses are available on our online platform and
          at our Noida & Gurugram centres.
        </p>
        <div className="flex gap-3">
          <button className="bg-[#ff007f] text-white px-4 py-2 rounded-md shadow  transition">
            Read More
          </button>
          <button className="bg-white text-gray-800 border border-gray-300 px-4 py-2 rounded-md shadow hover:border-gray-800 hover:text-gray-900 transition">
            Contact Us
          </button>
        </div>
      </div>
      <div className="w-full md:w-1/2 flex  justify-center mt-6 md:mt-0">
        <img
          src={Graph}
          alt="Campaign Image"
          className="w-full max-w-lg shadow-md border-2 rounded-2xl object-cover"
        />
      </div>
    </div>
  );
};

export default Campaign;
