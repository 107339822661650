import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";

//  landing page sections
import About from "./about-section/About";
import Reporting from "./reporting-section/Reporting";
import Campaign from "./campaign-section/Campaign";
import Testimonials from "./testinomials/Testimonials";
import Brands from "./brands-section/Brands";
import Stats from "./stats-demo/Stats";
import logo2 from "../../assets/logo2.png";
import imgPlus from "../../assets/imgPlus.png";
import Newsletter from "./Newsletter/Newsletter";
//import Vector from "../../assets/Vector.png";

const LandingPage = () => {
  const navigate = useNavigate();
  const authData = useSelector((state) => state.auth.authData);

  const handleJoinClick = () => {
    navigate("/signup");
    // toast.success("You Clicked Waitlist!",{
    //   duration:2000,
    // });
  };

 

 

  return (
    <>
      <Toaster />
      {/* Section 1 - Hero Section */}
      <div className="flex flex-col items-center justify-center my-10 px-4 py-16  bg-white text-center">
        {/* Text Section */}
        <div className="mb-4 mt-100" style={{ fontFamily: "Gilroy-Light", }}>
          <h1 className="text-3xl md:text-5xl font-bold leading-snug">
            India’s first pay as you go creator platform.
            <br />
          </h1>
          <h1 className="text-3xl md:text-5xl font-bold leading-snug mt-2">
            <img
              src={logo2}
              alt="inggage"
              className="inline-block h-10 align-middle mx-2"
            />
            with
            <img
              src={imgPlus}
              alt="inggage"
              className="inline-block h-12 align-middle mx-2"
            />
            creators and brands
          </h1>
        </div>

        {/* Paragraph Section */}
        <p
          className="text-lg md:text-lg text-black mb-8"
          style={{ fontFamily: "Gilroy-Medium", width: "80%" }}
        >
          Our AI-powered platform optimizes your campaigns in real time, using
          real-time analytics and payment views to ensure you only pay for
          measurable results.
        </p>

        {/* Buttons Section */}
        <div
          className="flex flex-col md:flex-row items-center justify-center gap-4"
          style={{ fontFamily: "Gilroy-Medium" }}
        >
          <button
            className="px-4 py-2 text-white bg-primaryButton rounded-lg shadow-md hover:bg-pink-600 transition"
            onClick={handleJoinClick}
          >
            Join the Waitlist
          </button>
          <button
            className="px-4 py-2 bg-white text-gray-800 border border-gray-300 rounded-lg shadow-md hover:bg-gray-100 transition"
            onClick={() =>
              (window.location.href =
                "mailto:suryansu@inggage.com?subject=My%20message%20to%20Inggage")
            }
          >
            Contact Us
          </button>
        </div>
      </div>

      {/* Stats chart section*/}
      <Stats />

      {/* Brands Animation Section */}
      <Brands />

      {/* About Section */}
      <About />

      {/* Advanced reporting Section */}
      <Reporting />

      {/* Campaign Poster */}
      <Campaign />

      {/* Testimonials */}
      <Testimonials />

      {/* Newsletter-section */}

      <Newsletter />
    </>
  );
};

export default LandingPage;
