import React from "react";
import styles from "./Brands.module.css";

// Brands assets
import ceat from "../../../assets/brands/ceat.png";
import ajio from "../../../assets/brands/ajio.png";
import dbs from "../../../assets/brands/dbs.png";
import tata from "../../../assets/brands/tata.png";
import magicbrush from "../../../assets/brands/magicbrush.png";
import gym from "../../../assets/brands/gym.png";
import studio from "../../../assets/brands/studio.png";
import lyzr from "../../../assets/brands/lyzr.png";
import Acciojob from "../../../assets/brands/Acciojob.png";
import purplle from "../../../assets/brands/purplle.png";

// Data for brand logos
const brandLogos = [
  { id: 1, src: lyzr, alt: "Lyzr Logo" },
  { id: 2, src: purplle, alt: "Purplle Logo" },
  { id: 3, src: Acciojob, alt: "Acciojob Logo" },
  { id: 4, src: ceat, alt: "Ceat Logo" },
  { id: 5, src: ajio, alt: "Ajio Logo" },
  { id: 6, src: dbs, alt: "DBS Logo" },
  { id: 7, src: magicbrush, alt: "MagicBrush Logo" },
  { id: 8, src: tata, alt: "Tata Logo" },
  { id: 9, src: gym, alt: "Gym Logo" },
];

const Brands = () => {
  return (
    <div className="brands-section">
      <h1 className="text-2xl font-bold text-center mb-4">Brands We've Worked With</h1>
      <div
        className="brands-logos mx-[30px]  flex flex-wrap my-4 items-center justify-center "
        style={{
          animation: "marquee 15s linear infinite",
        }}
      >
        {brandLogos.map((brand) => (
          <div
            key={brand.id}
            className="brand-logo flex-none mr-5 my-3 flex flex-wrap items-center justify-center"
            style={{ width: "80px" }}
          >
            <img src={brand.src} alt={brand.alt} className="w-full h-auto" />
          </div>
        ))}
      </div>
      
    </div>
  );
};

export default Brands;
