import React, { useState } from "react";
import styles from "./InfluencerProfile.module.css";
import CampaignInfo from "./CampaignInfo/CampaignInfo";
import BrandsInfo from "./BrandsInfo/BrandsInfo";
import ProfileInfo from "./ProfileInfo/ProfileInfo";
import Banner from "../../assets/images/banner.png";
import Media from "./Media";

import { useSelector, useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import { uploadImage } from "../../actions/UploadAction";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

import io from "socket.io-client";

//const socket = io("http://localhost:5000");


function InfluencerProfile({AccessToken}) {
  const location = useLocation();
  const { profile } = location.state || {};

  const param = useParams();
  const [preview, setPreview] = useState(null);
  //const [profileData, setprofileData] = useState(null);
  const [uploadPopup, setUploadpopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [activeTab, setActiveTab] = useState("pastCampaigns");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let profileData = useSelector((state) => state.auth.authData?.user);

  let isBrand = false;
  if (profileData.userType === "Brand") {
    isBrand = true;
    profileData = profile;
  }
  //console.log(profileData.userType)
  // if(influencer){
  //   setprofileData(influencer)
  // }
  // useEffect(() => {
  //     if (influencer?.userType === "Brand" && profile) {
  //       // When a brand is logged in, show the passed profile data
  //       setprofileData(profile);
  //     } else if (influencer?.userType === "Influencer") {
  //       // When an influencer is logged in, use their own data
  //       setprofileData(influencer);
  //     }
  //   }, [influencer, profile]);

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  const handleUserUpdate = () => {
    const id = profileData._id;
    navigate(`/influencer/update/${id}`);
  };

  // const handleUpload = (id) => {
  //   console.log(id);
  //   alert(`upload id ${id}`);
  // };

  const closePopup = () => {
    setUploadpopup(!uploadPopup);
  };
  const handleImagePopup = (e) => {
    e.preventDefault();
    setUploadpopup(!uploadPopup);
  };

  const handleImageUpload = (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (file) {
      setSelectedImage(file);
      //console.log(selectedImage)
      setPreview(URL.createObjectURL(file));

      // console.log(filedata.getAll);
    }
  };

  const updateProfileImage = async () => {
    const influencerId = param.id;
    console.log("Selected Image URL: ", selectedImage);

    if (!selectedImage) {
      console.error("No image selected");
      return;
    }
    setUploadpopup(!uploadPopup);

    const filedata = new FormData();
    const fileName = Date.now() + selectedImage.name;
    filedata.append("name", fileName);
    filedata.append("file", selectedImage);
    try {
      await dispatch(uploadImage(influencerId, filedata));

      // After image is successfully uploaded, update the user

      console.log("Profile image updated successfully");
    } catch (err) {
      console.log(err);
    }
  };

  const memberSince = () => {
    const date = new Date(profileData.createdAt);
    const month = date.toLocaleString("default", { month: "long" }); // Returns full month name (e.g., 'September')
    const year = date.getFullYear(); // Returns year (e.g., 2024)

    // Concatenate month and year
    const monthYear = `${month} ${year}`;
    return monthYear;
  };
  return (
    <>
      
      {uploadPopup && (
        <>
          <div className="overlay" onClick={closePopup}></div>{" "}
          {/* Overlay for background */}
          <div className={styles.UplaodPopUp}>
            {/* Image Upload Input */}
            <div className={styles.uploadSection}>
              <label htmlFor="imageUpload" className={styles.uploadLabel}>
                <h3>Upload Image:</h3>
              </label>
              <input
                type="file"
                id="imageUpload"
                accept="image/*"
                onChange={handleImageUpload}
              />

              {/* Image Preview (Optional) */}
              {selectedImage && (
                <div className={styles.imagePreview}>
                  <img src={preview} alt="Preview" />
                </div>
              )}
              <button
                className="btn btn-primary  rounded-5"
                style={{ background: "#ff007f" }}
                onClick={updateProfileImage}
              >
                Update Profile Image
              </button>
              <button
                className="btn btn-primary mx-1 rounded-5"
                onClick={closePopup}
              >
                Close
              </button>
            </div>
          </div>
        </>
      )}

      <div className={styles.influencerProfile}>
        {/* Banner Section */}
        <div className={styles.banner}>
          <img
            src={Banner}
            alt="Banner Background"
            className={styles.bannerImage}
          />
          <div className={styles.profileSection}>
            {isBrand ? (
              <img
                src={profileData.profile_image}
                alt="Profile"
                className={styles.profileImage}
              />
            ) : (
              <>
                <img
                  src={profileData.profile_image}
                  alt="Profile"
                  className={styles.profileImage}
                  onClick={handleImagePopup}
                />
              </>
            )}

            <div className={styles.profileDetails}>
              <h1>Hi, I'm {profileData.displayname}</h1>
              <p>Life's too short for boring Marketing!</p>

              <div className={styles.ratingLocation}>
                <div
                  className={styles.rating}
                  style={{
                    color: "white",
                    backgroundColor: "rgba(43, 43, 43, 0.36)",
                    padding: "0.2rem 0.5rem",
                    borderRadius: "5px",
                  }}
                >
                  <span>⭐⭐⭐⭐⭐ Reviews</span>
                  <br />
                  <span>Coming soon</span>
                </div>
              </div>
              <div className={styles.contactViews}>
              <button className={styles.contactButton}>Contact</button>
           <div className={styles.views}>{profileData.ER}% ER</div> 
              </div>
            </div>
          </div>
          <div className={styles.extraDetails}>
            <span>Member since: {memberSince()}</span>
            <span>Last Active: about 2 hours</span>
            <span>Responds in 4 Hrs</span>
          </div>
        </div>

        <div className={styles.BelowContainer}>
          {/* Description Section */}
          <div className={styles.descriptionContainer}>
            <ProfileInfo
              profileData={profileData}
              isBrand={isBrand}
              handleUserUpdate={handleUserUpdate}
            />
          </div>

          {/* Description Section */}
          <div className={styles.toggleContainer}>
            <div className={styles.toggleButtons}>
              <button
                className={
                  activeTab === "pastCampaigns" ? styles.activeButton : ""
                }
                onClick={() => toggleTab("pastCampaigns")}
              >
                Past Campaigns
              </button>
              <button
                className={activeTab === "brands" ? styles.activeButton : ""}
                onClick={() => toggleTab("brands")}
              >
                Brands
              </button>
            </div>
            <div className={styles.content}>
              {activeTab === "pastCampaigns" ? (
                <div className="flex flex-col gap-4">
                  <CampaignInfo />
                  <Media media={profileData.media}/>
                </div>
              ) : (
                <div className={`${styles.brands}`}>
                  <BrandsInfo />
                  <Media media={profileData.media}/>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!isBrand && (
        <div className={styles.ButtonDiv}>
          <button
            className="btn btn-warning rounded-5 fw-bold"
            onClick={handleUserUpdate}
          >
            Update
          </button>
        </div>
      )}
    </>
  );
}

export default InfluencerProfile;
