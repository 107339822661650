import React from "react";
import testimonialsData from "./TestimonialsData";

const Testimonials = () => {
  const brands = testimonialsData.filter((testimonial) => testimonial.type === "brand");
  const creators = testimonialsData.filter((testimonial) => testimonial.type === "creator");

  return (
    <div className="flex flex-col items-center justify-center px-4" style={{fontFamily:"Gilroy-Medium"}}>
      <div className="flex flex-col items-center justify-center text-center animate-fadeIn">
        <h2 className="text-4xl font-semibold mb-2 text-black mt-10">Testimonials</h2>
        <p className="text-base text-gray-700 mx-4 lg:mx-24 mb-10">
          Discover the power of data-driven decision-making with our Sales Report SaaS platform. Our app is
          designed to transform your sales data into actionable insights, providing you with real-time reports,
          advanced analytics.
        </p>
      </div>

      {/* Brand Testimonials */}
      <div className="w-full mb-8">
        <h3 className="text-2xl font-bold mb-4 align-center text-center">Brands</h3>
        <div className="flex flex-wrap justify-center gap-5">
          {brands.map((testimonial, index) => (
            <div
              className="bg-white border-2 border-gray-300 rounded-lg p-3 w-72 text-left shadow-sm animate-fadeIn"
              key={index}
            >
              <div className="flex items-center mb-2">
                <img
                  className="h-8 w-auto rounded-md mr-4"
                  src={testimonial.image}
                  alt={testimonial.name}
                />
                <div>
                  <h3 className="text-sm mb-0 font-semibold text-gray-800">{testimonial.name}</h3>
                  <span className="text-xs text-gray-500">{testimonial.brand}</span>
                </div>
              </div>
              <p className="text-sm mb-0 text-gray-600">{testimonial.text}</p>
              <div className="text-yellow-500 text-lg mt-2">⭐ {testimonial.rating}</div>
            </div>
          ))}
        </div>
      </div>

      {/* Creator Testimonials */}
      <div className="w-full">
        <h3 className="text-2xl font-bold mb-4 text-center">Creators</h3>
        <div className="flex flex-wrap justify-center gap-5">
          {creators.map((testimonial, index) => (
            <div
              className="bg-white border-2 border-gray-300 rounded-lg p-3 w-72 text-left shadow-sm animate-fadeIn"
              key={index}
            >
              <div className="flex items-center mb-2">
                <img
                  className="h-8 w-auto rounded-md mr-4"
                  src={testimonial.image}
                  alt={testimonial.name}
                />
                <div>
                  <h3 className="text-sm mb-0 font-semibold text-gray-800">{testimonial.name}</h3>
                  <span className="text-xs text-gray-500">{testimonial.brand}</span>
                </div>
              </div>
              <p className="text-sm mb-0 text-gray-600">{testimonial.text}</p>
              <div className="text-yellow-500 text-lg mt-2">⭐ {testimonial.rating}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
