import React, { useState } from "react";
import styles from "./CampaignPerformance.module.css";
// import { useSelector } from "react-redux";
import { HiRefresh } from "react-icons/hi";
import baseUrl from "../../../api/universalApi";

const CampaignPerformance = ({ views, handleCreator, selectedCreator, campaignData }) => {
  // const campaign = useSelector((state) => state.campaigns?.campaigns);
  const [selectedCreator1, setSelectedCreator1] = React.useState(null);
  const [isRotating, setIsRotating] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedNiche, setSelectedNiche] = useState("all");

  // Get unique niches from creators
  // const uniqueNiches = campaignData?.creators 
  //   ? [...new Set(campaignData.creators.map(creator => creator.niche?.[0] || "Comedy"))]
  //   : [];

  const handleCreatorClick = (creator) => {
    handleCreator(creator);
    setSelectedCreator1(creator);
  };

  const handleRefersh = async () => {
    setIsRotating(true);

    try {
      const response = await fetch(
        `${baseUrl}/campaign/campaignCreatorRefresh`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaignId: campaignData._id,
            creator: selectedCreator,
          }),
        }
      );
      const data = await response.json();
      console.log("data", data);
      setTimeout(() => {
        setIsRotating(false);
      }, 2000);
    } catch (error) {
      console.error("Error refreshing creator data:", error);
    }
  };

  // Filter creators based on search term and selected niche
  const filteredCreators = campaignData?.creators?.filter(creator => {
    const matchesSearch = creator.displayname.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesNiche = selectedNiche === "all" || creator.niche?.[0] === selectedNiche;
    return matchesSearch && matchesNiche;
  });

  if (!campaignData) {
    return (
      <div className={styles.campaignContainer}>
        <div className={styles.campaignViews}>
          <h4>Campaign Performance</h4>
          <p>No campaign data available</p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.campaignContainer}>
      {/* Campaign Header */}
      <div className={styles.campaignViews}>
        <div>
          <h4>Campaign Creator</h4>
         
          <p className={styles.noMarginBottom}>
            <strong>Creators</strong>
          </p>
        </div>
      </div>

      {/* Search and Filter Section */}
      <div className={styles.searchFilterContainer}>
        <div className={styles.searchFilterWrapper}>
          <input
            type="text"
            placeholder="Search creators..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={styles.searchInput}
          />
          {/* <select
            value={selectedNiche}
            onChange={(e) => setSelectedNiche(e.target.value)}
            className={styles.nicheSelect}
          >
            <option value="all">All Niches</option>
            {uniqueNiches.map((niche) => (
              <option key={niche} value={niche}>
                {niche}
              </option>
            ))}
          </select> */}
        </div>
      </div>

      {/* Campaign Details */}
      <div className={styles.CreatorDetailsBox}>
        <div className={styles.CampaignDetails}>
          <div className={styles.CampaignName}>
            <div className={styles.CreatorDetail}>
              <div className={styles.creatorList}>
                {filteredCreators && filteredCreators.length > 0 ? (
                  filteredCreators.map((creator) => (
                    <li
                      key={creator._id}
                      onClick={() => handleCreatorClick(creator)}
                      className={`${styles.creatorItem} ${
                        selectedCreator1?._id === creator._id
                          ? styles.selectedCreator
                          : ""
                      }`}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center gap-2">
                          <img
                            src={creator.profile_image}
                            alt="Creator"
                            className="rounded-circle border"
                            style={{ width: "25px", height: "25px" }}
                          />
                          <div>
                            <span className="fw-medium">
                              {creator.displayname}
                            </span>{" "}
                           
                          </div>
                        </div>

                        <div flex="1" className="d-flex justify-content-end">
                          <div className="mx-4">
                            <a
                              href={creator.reelLink}
                              alt="reel"
                              target="_blank"
                              rel="noreferrer"
                              className="text-white text-decoration-none"
                            >
                              View Reel
                            </a>
                          </div>
                          {selectedCreator?.id === creator.id && (
                            <span style={{ cursor: "pointer" }}>
                              <HiRefresh
                                onClick={handleRefersh}
                                className={`${
                                  isRotating ? styles.rotateIcon : ""
                                }`}
                              />
                            </span>
                          )}
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <li className="text-gray-500">No creators found</li>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Campaign Stats */}
      <div className={styles.campaignStats}>
        <div className={styles.statItem}>
          <p>Start Date</p>
          <strong>
            {new Date(campaignData.startDate).toLocaleDateString()}
          </strong>
        </div>
        <div className={styles.statItem}>
          <p>End Date</p>
          <strong>{new Date(campaignData.endDate).toLocaleDateString()}</strong>
        </div>
      </div>
    </div>
  );
};

export default CampaignPerformance;
