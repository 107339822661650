import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";

const socket = io("http://localhost:8080"); // Backend server URL

const Chat = () => {
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [activeUser, setActiveUser] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [chats, setChats] = useState({}); // Stores messages for each user
  const user = useSelector((state) => state.auth.authData?.user);

  useEffect(() => {
    // Listen for online users from the server
    socket.on("update_online_users", (users) => {
      setOnlineUsers(users);
    });

    // Listen for incoming private messages
    socket.on("receive_private_message", ({ sender, message }) => {
      setChats((prevChats) => ({
        ...prevChats,
        [sender]: [...(prevChats[sender] || []), { sender, message }],
      }));
    });

    // Notify server of the user's presence
    socket.emit("join_chat", user.displayname);

    return () => {
      socket.disconnect();
    };
  }, [user.displayname]);

  // Handle sending a message
  const handleSendMessage = () => {
    if (newMessage.trim() && activeUser) {
      const message = newMessage.trim();

      // Send the private message to the server
      socket.emit("send_private_message", {
        sender: user.displayname,
        receiver: activeUser,
        message,
      });

      // Update chat optimistically
      setChats((prevChats) => ({
        ...prevChats,
        [activeUser]: [
          ...(prevChats[activeUser] || []),
          { sender: "You", message },
        ],
      }));

      setNewMessage(""); // Clear the input field
    }
  };

  return (
    <div className="flex  h-screen">
      {/* <p>Update soon</p> */}

      <div className="w-1/4 max-w-sm bg-white border-r border-gray-200">
        <div className="p-4 border-b">
          <h1 className="text-xl font-bold text-gray-700">Online Users</h1>
        </div>
        <ul className="px-2">
          {onlineUsers.length > 0 ? (
            onlineUsers.map((username, index) => (
              <li
                key={index}
                onClick={() => setActiveUser(username)}
                className={`flex items-center p-2 rounded cursor-pointer hover:bg-gray-100 ${
                  activeUser === username ? "bg-gray-200" : ""
                }`}
              >
                <FaUser className="w-6 h-6 bg-pink-600 text-white p-1 rounded-full" />
                <div className="ml-3">
                  <h2 className="text-sm font-semibold text-gray-700">
                    {username}
                  </h2>
                </div>
              </li>
            ))
          ) : (
            <p className="text-gray-500 text-sm px-4">No users online</p>
          )}
        </ul>
      </div>

      <div className="flex-grow flex flex-col">
        {activeUser ? (
          <>
            <div className="flex items-center justify-between bg-white p-4 border-b">
              <div className="flex items-center">
                <FaUser className="rounded-full p-1 text-white bg-pink-600 w-6 h-6" />
                <div className="ml-3">
                  <h2 className="text-lg font-bold">{activeUser}</h2>
                </div>
              </div>
            </div>

            <div className="flex-grow overflow-y-auto p-4 bg-gray-50">
              {chats[activeUser]?.length > 0 ? (
                chats[activeUser].map((chat, index) => (
                  <div
                    key={index}
                    className={`flex ${
                      chat.sender === "You" ? "justify-end" : "justify-start"
                    } mb-3`}
                  >
                    <div
                      className={`max-w-xs px-4 py-2 rounded-lg ${
                        chat.sender === "You"
                          ? "bg-blue-500 text-white"
                          : "bg-gray-200 text-gray-700"
                      }`}
                    >
                      <p className="text-sm">{chat.message}</p>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-gray-500 text-sm">No messages yet.</p>
              )}
            </div>

            <div className="flex items-center bg-white p-4 border-t">
              <input
                type="text"
                placeholder="Type a message"
                className="flex-grow px-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
              />
              <button
                onClick={handleSendMessage}
                className="ml-3 bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600"
              >
                Send
              </button>
            </div>
          </>
        ) : (
          <div className="flex-grow flex items-center justify-center">
            <p className="text-gray-500 text-sm">
              Select a user to start chatting.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;
