import React, { useState } from "react";
import useConversation from "../statemanage/useConversation.js";
import axios from "axios";
import baseUrl from "../api/universalApi.js";



const useSendMessage = () => {
  const [loading, setLoading] = useState(false);
  const { messages, setMessage, selectedConversation } = useConversation();
  const sendMessages = async (message, influencerId, brandId, userType) => {
  //  console.log(message, influencerId, brandId, userType)
    setLoading(true);
    try {
        const res = await axios.post(
            `${baseUrl}/api/message/send/${influencerId}`,
            {
              message, // Message content
              brandId, // Brand's ID
              userType // User type (optional, depending on your API needs)
            }
          );
      setMessage([...messages, res.data]);
      setLoading(false);
    } catch (error) {
      console.log("Error in send messages", error);
      setLoading(false);
    }
  };
  return { loading, sendMessages };
};

export default useSendMessage;