import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { saveCampaignData } from "../../../actions/BrandActions";

const CustomLineChart = ({ handleViews, graphData }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedGraph, setSelectedGraph] = useState("all"); // Default to "all" graphs

  const campaignData = useSelector((state) => state.campaigns.campaigns.data);

  useEffect(() => {
    if (graphData) {
      setData(graphData);
    }
  }, [graphData]);

  const handleSelectionChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    if (newValue) {
      handleRefresh(newValue);
    }
  };

  const handleGraphChange = (event) => {
    setSelectedGraph(event.target.value);
  };

  const handleRefresh = async (value) => {
    try {
      if (value) {
        const response = await axios.post(`/api/campaign-refresh`, {
          campaignName: value,
        });

        const fetchedData = response.data.data;
        const campaignData = response.data.campaignData;

        const matchedCampaignIndex = campaignData.findIndex(
          (campaign) => campaign.campaignName === value
        );

        setData(fetchedData);
        dispatch(saveCampaignData(campaignData));

        handleViews(fetchedData, matchedCampaignIndex);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formattedData = data
    .map((entry) => {
      const date = new Date(entry.timestamp);
      if (isNaN(date)) {
        console.warn(`Invalid timestamp detected: ${entry.timestamp}`);
        return null;
      }
      const time = date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });

      return {
        name: time,
        likes: entry.likes,
        views: entry.views,
        shares: entry.shares,
        comments: entry.comments,
        earnings: entry.earning,
        timestamp: entry.timestamp,
      };
    })
    .filter((entry) => entry !== null);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, likes, views, shares, comments, earnings } =
        payload[0].payload;
      return (
        <div
          className="p-2 bg-white rounded-lg shadow-lg"
          style={{ fontFamily: "Gilroy-Medium" }}
        >
          <p className="font-bold text-gray-700 mb-0">{`Time: ${name}`}</p>
          <p className="text-sm text-gray-500 mb-0">{`Date: ${new Date(
            payload[0].payload.timestamp
          ).toLocaleDateString()}`}</p>
          <p className="text-sm text-blue-600 mb-0">{`Likes: ${likes}`}</p>
          <p className="text-sm text-purple-600 mb-0">{`Views: ${views}`}</p>
          <p className="text-sm text-green-600 mb-0">{`Shares: ${shares}`}</p>
          <p className="text-sm text-red-600 mb-0">{`Comments: ${comments}`}</p>
          <p className="text-sm text-yellow-600 mb-0">{`Earnings: ${earnings}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className="w-full px-2 py-3 bg-gray-50 rounded-lg shadow-md"
      style={{ fontFamily: "Gilroy-Medium" }}
    >
      {/* Header */}
      <div className="mb-4">
        <h2 className="text-2xl font-semibold text-gray-800">
          Campaign Performance
        </h2>
        <div className="flex flex-col gap-2 sm:flex-row sm:items-center mt-3">
          <select
            value={selectedGraph}
            onChange={handleGraphChange}
            className="p-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500"
          >
            <option value="all">All Metrics</option>
            <option value="likes">Likes</option>
            <option value="views">Views</option>
            <option value="shares">Shares</option>
            <option value="comments">Comments</option>
            <option value="earnings">Earnings</option>
          </select>
        </div>
      </div>

      {/* Chart */}
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={formattedData}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis />
          <YAxis
            tickFormatter={(value) => {
              if (value >= 1000) {
                return `${(value / 1000).toFixed(1)}k`; // Format numbers over 1000 as '1.5k', '2k', etc.
              }
              return value; // Return the original value if it's less than 1000
            }}
          />
          <Tooltip content={<CustomTooltip />} />

          <Tooltip content={<CustomTooltip />} />

          {/* Conditionally Render Areas Based on Selected Graph */}
          {(selectedGraph === "all" || selectedGraph === "likes") && (
            <Area
              type="monotone"
              dataKey="likes"
              name="Likes"
              stroke="#EA0063"
              fill="#EA0063"
              opacity={0.3}
            />
          )}
          {(selectedGraph === "all" || selectedGraph === "views") && (
            <Area
              type="monotone"
              dataKey="views"
              name="Views"
              stroke="#8884d8"
              fill="#8884d8"
              opacity={0.3}
            />
          )}
          {(selectedGraph === "all" || selectedGraph === "shares") && (
            <Area
              type="monotone"
              dataKey="shares"
              name="Shares"
              stroke="#00FF00"
              fill="#00FF00"
              opacity={0.3}
            />
          )}
          {(selectedGraph === "all" || selectedGraph === "comments") && (
            <Area
              type="monotone"
              dataKey="comments"
              name="Comments"
              stroke="#FF6347"
              fill="#FF6347"
              opacity={0.5}
            />
          )}
          {(selectedGraph === "all" || selectedGraph === "earnings") && (
            <Area
              type="monotone"
              dataKey="earnings"
              name="Earnings"
              stroke="#FFD700"
              fill="#FFD700"
              opacity={0.3}
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomLineChart;
