import React, { useEffect, useState } from "react";
import styles from "./CampaignPerformance.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import baseUrl from "../../../api/universalApi";


const CampaignPerformance = ({ handleCreator, creatorData }) => {
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [tempReelLink, setTempReelLink] = useState(""); // Temporary state for typing
  const [reelLink, setReelLink] = useState(""); // Final state for upload
  const campaignData = useSelector((state) => state.campaigns.campaigns?.data);
  const authData = useSelector((state) => state.auth.authData);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
const dispatch = useDispatch();
  const handleCreatorClick = (creator) => {
    handleCreator(creator);
  };

  useEffect(() => {
    if (campaignData && campaignData[0]?.creators?.length > 0) {
      const creator = campaignData
        .flatMap((campaign) => campaign.creators) // Flatten creators from all campaigns
        .find((creator) => creator._id === authData.user._id);
      // console.log("Creator Found:", creator);
      handleCreatorClick(creator);
    }
  }, [campaignData]);

  const handleUploadReelLink = () => {
    // console.log("Reel Link", tempReelLink);
    setReelLink(tempReelLink); // Update reelLink only when upload button is clicked
    setShowUploadPopup(false);
  };

  if (!campaignData) {
    return (
      <div className={styles.campaignContainer}>
        <div className={styles.campaignViews}>
          <h4>Campaign Performance</h4>
          <p>No campaign data available</p>
        </div>
      </div>
    );
  }

  const handleNavigateToProfile = () => {
    navigate(`/influencer/${creatorData._id}`); // Navigate to the profile page
  };

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleSaveReelLink = async () => {
    const trimReelLink = (link) => {
      // Check if the link starts with the correct base URL
      if (!link.startsWith("https://www.instagram.com/reel/")) {
        return null; // Invalid format
      }
  
      // Extract the main part of the reel link
      const mainPart = link.split("?")[0]; // Remove query parameters (e.g., ?utm_source=...)
      return mainPart.endsWith("/") ? mainPart : `${mainPart}/`; // Ensure it ends with a "/"
    };
  
    const trimmedReelLink = trimReelLink(reelLink);
  
    if (trimmedReelLink) {
      localStorage.setItem("ReelLink", trimmedReelLink);
  
      try {
        const payload = {
          campaignId:campaignData[0]?._id,
          userId: authData?.user?._id, // User ID
          instaId: authData?.user?.instaId, // Instagram ID
          accessToken: authData?.user?.longLivedAccess, // Access Token
          reelLink: trimmedReelLink, // Trimmed Reel Link
        };
  
        console.log("Payload to be sent:", payload);
  
        // Hit the API
        const response = await axios.post(`${baseUrl}/campaign/reelUpload`, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        });
  
        if (response.data?.success) {
          alert(response.data?.message);
          console.log(response.data?.data);
          const data = response.data;
          dispatch({ type: "SET_CAMPAIGN_DATA", payload: data });
          console.log("Backend Response:", response.data);
        } else {
          console.error("Backend Error:", response.data?.message);
          alert("Failed to retrieve media ID. Please try again.");
        }
      } catch (error) {
        console.error("Error hitting the API:", error);
        alert("An error occurred while processing the reel link.");
      }
  
      setShowPopup(false);
      setReelLink("");
    } else {
      alert("Please enter a valid reel link in the correct format.");
    }
  };
  

  return (
    <div className={styles.campaignContainer}>
      {/* Campaign Header */}
      <div className={styles.campaignViews}>
        <div>
          <h4>Campaign Creator</h4>
          <p>
            <strong>Campaign Name:</strong>{" "}
            {campaignData[0]?.campaignName || "No Campaign"}
          </p>
          <p>
            <strong>Goal:</strong>{" "}
            {campaignData[0]?.campaignGoal || "No goal set"}
          </p>{" "}
          <p style={{ marginBottom: "0px" }}>
            <strong>Creators</strong>
          </p>
        </div>
      </div>

      {/* Campaign Details */}
      <div className={styles.CreatorDetailsBox}>
        <div className={styles.CampaignDetails}>
          <div className={styles.CampaignName}>
            <div className={styles.CreatorDetail}>
              <div className="flex flex-col gap-2 mt-0">
                {creatorData && (
                  <li
                    key={creatorData.id}
                    onClick={() => handleCreatorClick(creatorData)}
                    className={`${styles.creatorItem} py-2 px-2 rounded-lg cursor-pointer hover:bg-gray-100 transition-colors`}
                  >
                    <div className="flex flex-row">
                      <img
                        src={creatorData.profile_image}
                        alt="Creator"
                        className={styles.creatorLogo}
                      />
                      <span className="font-medium">
                        {creatorData.displayname}
                      </span>
                      {"  "}
                     
                    </div>
                  </li>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-4">
        {campaignData.length > 0 && (
          <>
            {!creatorData?.instaId ? (
              <div className="text-center">
                <p className="text-danger fw-bold">
                  Instagram isn't connected. Please connect.
                </p>
                <button
                  onClick={handleNavigateToProfile}
                  className="btn btn-primary mt-2"
                >
                  Connect Instagram
                </button>
              </div>
            ) : !creatorData?.mediaID ? (
              <div className="text-center">
                <p className="text-warning fw-bold">
                  Please provide a reel link.
                </p>
                <button
                  onClick={handleOpenPopup}
                  className="btn btn-success mt-2"
                >
                  Click Here
                </button>
              </div>
            ) : (
              <div className="text-left text-success fw-light">
                <a href={creatorData.reelLink} rel="noreferrer" target="_blank">Reel</a>
              </div>
            )}
          </>
        )}

        {/* Popup Box for Reel Link */}
        {showPopup && (
          <div
            className="modal show d-flex align-items-center justify-content-center"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", height: "100vh" }}
          >
            <div className="modal-dialog w-100">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Provide Reel Link</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={handleClosePopup}
                  ></button>
                </div>
                <div className="modal-body">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Paste your reel link here..."
                    value={reelLink}
                    onChange={(e) => setReelLink(e.target.value)}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleClosePopup}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSaveReelLink}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={styles.campaignStats}>
        <div className={styles.statItem}>
          <p>Start Date</p>
          <strong>
            {new Date(campaignData[0]?.startDate).toLocaleDateString()}
          </strong>
        </div>
        <div className={styles.statItem}>
          <p>End Date</p>
          <strong>
            {new Date(campaignData[0]?.endDate).toLocaleDateString()}
          </strong>
        </div>
      </div>
    </div>
  );
};

export default CampaignPerformance;
