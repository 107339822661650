import React, { useEffect, useState } from 'react';
import axios from 'axios';
import baseUrl from '../../api/universalApi';
import { useNavigate } from 'react-router-dom';

const RunningCampaign = ({ creators }) => {
  const [campaigns, setCampaigns] = useState([]);
  const [status, setStatus] = useState("Go live");
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [filteredCreators, setFilteredCreators] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate()

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/campaigns`);
        setCampaigns(response.data.data);
      } catch (error) {
        console.error('Error fetching campaigns:', error);
      }
    };

    fetchCampaigns();
  }, []);

  const handleGoLive = async(creator)  => {
    console.log(creator)
    try {
      const response = await axios.post(`${baseUrl}/campaign/campaignlaunch`, {
        creator: JSON.stringify(creator),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Launch response:', response);
      setStatus("Live");
    } catch (error) {
      console.error('Error launching campaign:', error);
    }
  };

  const handleAddCreator = (campaignId) => {
    setSelectedCampaign(campaignId);
    setFilteredCreators([]);
    setSearchTerm("");
  };

  const handleFilterCreators = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filtered = creators.filter((creator) =>
      creator.displayname.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredCreators(filtered);
  };

  const handleAddToCampaign = async (campaignId, creatorId) => {
    try {
      const response = await axios.post(`${baseUrl}/admin/addCreatorToCampaign`, {
        campaignID: campaignId,
        creatorID: creatorId,
      });
      console.log('Add creator response:', response);
      alert('Creator added successfully!');
      setSelectedCampaign(null); // Reset after adding
    } catch (error) {
      console.error('Error adding creator to campaign:', error);
      alert('Failed to add creator to campaign.');
    }
  };

  const HandleDashbaord =(id)=>{
    navigate(`/Brand/Analytics/${id}`)
  }

  return (
    <div className="container mt-4">
      <h1 className="mb-4">Running Campaigns</h1>
      <div className="row">
        {campaigns.map((campaign) => (
          <div key={campaign._id} className="col-md-6 mb-4">
            <div className="card">
              <div className="card-header">
                <h2 className="card-title">{campaign.campaignName} {"  "} </h2>
                <button className='btn btn-primary' onClick={()=>HandleDashbaord(campaign.brandID)}>See Dashboard</button>
              </div>
              <div className="card-body">
                <ul className="list-group list-group-flush">
                  {campaign.creators.map((creator) => (
                    <li key={creator._id} className="list-group-item">
                      <strong>{creator.displayname}</strong> - {creator.email}
                      {creator?.mediaID && (
                        <div className="mt-2">
                          <span className="badge bg-info">{creator.mediaID}</span>
                          <button
                            className="btn btn-primary mx-2 p-1"
                            onClick={() => handleGoLive(creator)}
                          >
                            {status}
                          </button>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
                <button
                  className="btn btn-secondary mt-3"
                  onClick={() => handleAddCreator(campaign._id)}
                >
                  Add Creator
                </button>
                {selectedCampaign === campaign._id && (
                  <div className="mt-3">
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="Search Creator by Name"
                      value={searchTerm}
                      onChange={handleFilterCreators}
                    />
                    <ul className="list-group">
                      {filteredCreators.map((creator) => (
                        <li
                          key={creator._id}
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          {creator.displayname} - {creator.email}
                          <button
                            className="btn btn-success btn-sm"
                            onClick={() => handleAddToCampaign(campaign._id, creator._id)}
                          >
                            Add
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RunningCampaign;
