import React, { useEffect, useState } from "react";
import BrandProfile from "../../BrandProfile/BrandProfile";
import { useSelector, useDispatch } from "react-redux";

const Home = ({user}) => {
 //console.log(user)

  return <BrandProfile  user={user}/>;
};

export default Home;
