import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteRequestedInfluencer } from "../../../actions/BrandActions"; // Import the delete action

const Requests = ({ requestedCreator }) => {
  const dispatch = useDispatch();

  // Initial state for influencers data
  const requests = useSelector((state) => state.creators.creators);
  const navigate = useNavigate();

  const [influencers, setInfluencers] = useState(requestedCreator);
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);
  const [formData, setformData] = useState({
    brandID: "",
    creatorID: "",
  });
  // Function to open the modal with influencer data
  const viewProfile = (influencer) => {
    setSelectedInfluencer(influencer);
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedInfluencer(null);
  };

  // Function to delete an influencer
  const deleteInfluencer = async (creatorID, brandID) => {
    if (!brandID) {
      console.error("Brand ID is required.");
      return;
    }

    // Dispatch action to delete the influencer
    // Dispatch action to delete the influencer
    try {
      await dispatch(deleteRequestedInfluencer(brandID, creatorID)).then(() => {
        // Remove influencer from local state if deletion is successful
        setInfluencers((prevInfluencers) =>
          prevInfluencers.filter(
            (influencer) => influencer.creatorID !== creatorID
          )
        );
        //console.log("Influencer deleted successfully.");
      });
    } catch (error) {
      console.error("Error deleting influencer:", error);
    }
  };

  const handleLaunchCampaign = () => {
    navigate("/createcampaign");
  };

  return (
    <div >
     

      <div className="container mx-auto  ">
        <h4 className="text-lg font-bold mb-4">Requested Influencers</h4>
        <ul className="space-y-6">
          {influencers.map((influencer, index) => (
            <li
              key={index}
              className="bg-white border rounded-lg shadow-sm hover:shadow-md p-4 flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4"
            >
              <img
                src={influencer.creatorImg}
                alt={influencer.creatorName}
                className="w-16 h-16 rounded-full object-cover border-2 border-gray-300"
              />
              <div className="flex-grow">
                <h4 className="text-base mb-0 font-semibold text-gray-800">
                  {influencer.creatorName}
                </h4>
                <p className="text-sm mb-1 text-gray-600">
                  {influencer.creatorEmail}
                </p>
                <p className="text-sm mb-1 text-gray-600">
                  Niche: {influencer.creatorNiche.join(", ")}
                </p>
              </div>
              <div className="flex space-x-4">
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition"
                  onClick={() => viewProfile(influencer)}
                >
                  View Profile
                </button>
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition"
                  onClick={() =>
                    deleteInfluencer(influencer.creatorID, influencer.brandID)
                  }
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {selectedInfluencer && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
          onClick={closeModal}
        >
          <div
            className="bg-white rounded-lg shadow-lg p-6 w-11/12 md:w-1/3 text-center"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-lg font-semibold text-gray-800 mb-4">
              {selectedInfluencer.creatorName}
            </h3>
            <img
              src={selectedInfluencer.creatorImg}
              alt={selectedInfluencer.creatorName}
              className="w-20 h-20 rounded-full object-cover mx-auto mb-4"
            />
            <p className="text-sm text-gray-600">
              Email: {selectedInfluencer.creatorEmail}
            </p>
            <p className="text-sm text-gray-600">
              Niche: {selectedInfluencer.creatorNiche.join(", ")}
            </p>
            <div className="mt-6 space-x-4">
              <button
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition"
                onClick={handleLaunchCampaign}
              >
                Launch Campaign
              </button>
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 transition"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Requests;
