import toast from "react-hot-toast";
import * as BrandAPI from "../api/BrandRequests";

// Define the action types as constants
export const GET_CREATORS_REQUEST = "GET_CREATORS_REQUEST";
export const GET_CREATORS_SUCCESS = "GET_CREATORS_SUCCESS";
export const GET_CREATORS_FAILURE = "GET_CREATORS_FAILURE";

export const GET_CAMPAIGN_REQUEST = "GET_CAMPAIGN_REQUEST";
export const SAVE_CAMPAIGN_REQUEST = "SAVE_CAMPAIGN_REQUEST";
export const GET_CAMPAIGN_FAILURE = "GET_CAMPAIGN_FAILURE";
export const SET_CAMPAIGN_DATA = "SET_CAMPAIGN_DATA";



// Action to get creators
export const RequestedCreator = (CreatorData) => async (dispatch) => {
  dispatch({ type: GET_CREATORS_REQUEST });

  try {
    const response = await BrandAPI.RequestedCreator(CreatorData);
    
    if (response.data.success) {
     // console.log(response.data);
    //  dispatch({ type: GET_CREATORS_SUCCESS, data: response.data });
      toast.success(response.data.message);
      return response.data;
    }  else if (!response.data.success) {
      dispatch({ type: GET_CREATORS_FAILURE, data: response.data  });
     // console.log(response.data);
      toast.error(response.data.message);
    }
  } catch (error) {
    dispatch({ type: GET_CREATORS_FAILURE });
    console.log(error);
    toast.error(error.response.data.message);
  }
};

export const GetRequestedList = (id) => async (dispatch) => {
  dispatch({ type: GET_CREATORS_REQUEST });
 
  try {
    //console.log(id);
    const response = await BrandAPI.GetRequestedList(id);
    if(!response.data.success){
      toast.error(response.data.message);
    }
    if (response.data.success) {
      //console.log(response.data);
      dispatch({ type: GET_CREATORS_SUCCESS, data: response.data.data });
    } 
    return response.data;
  } catch (error) {
    dispatch({ type: GET_CREATORS_FAILURE });
    console.log(error);
    toast.error(error.response.data.message);
  }
};



export const deleteRequestedInfluencer = (brandID,creatorID) => async (dispatch) => {
  dispatch({ type: GET_CREATORS_REQUEST });
 // console.log(creatorID)
  
  try {
    const response = await BrandAPI.deleteRequestedInfluencer(brandID,creatorID);
    console.log(response);
    if(!response.data.success){
      toast.error(response.data.message);
    }
    return response.data;
  } catch (error) {
    dispatch({ type: GET_CREATORS_FAILURE });
    console.log(error);
    toast.error(error.response.data.message);
  }
};

export const saveCampaignData = (data) => async (dispatch) => {
  dispatch({ type: GET_CAMPAIGN_REQUEST });

  try {
    //const response = await axios.post('/api/campaigns', data); // Adjust the API endpoint as needed

    dispatch({
      type: SAVE_CAMPAIGN_REQUEST,
      payload: data, // Assuming the API returns the saved campaign
    });

   // console.log("Campaign data saved successfully:", data);
  } catch (error) {
    dispatch({
      type: GET_CAMPAIGN_FAILURE,
      payload: error.response?.data?.message || error.message, // Handle API error messages
    });

    console.error("Error saving campaign data:", error);
  }
};

