import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import inggagePng from "../../assets/inggage.png";
import toast from "react-hot-toast";
import baseUrl from "../../api/universalApi";

const ForgetPassword = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setPassword] = useState("");
  const [error, setError] = useState("");
  const [role, setRole] = useState("Influencer");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleGetOtp = async () => {
    try {
      const response = await axios.post(`${baseUrl}/resetPassword/sendOTP`, {
        email,
        role,
      });
      console.log(response.data);
      if (response.data.success) {
        toast.success(response.data.message);
        setStep(2);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      setError("Failed to send OTP. Try again.");
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post(`${baseUrl}/resetPassword/verifyOTP`, {
        email,
        role,
        otp,
      });
      console.log(response.data);
      if (response.data.success) {
        toast.success(response.data.message);
        setStep(3);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      setError("Invalid OTP. Try again.");
    }
  };

  const handleResetPassword = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/resetPassword/resetPassword`,
        { email, newPassword: newPassword }
      );
      console.log(response.data);
      if (response.data.success) {
        toast.success(response.data.message);
        navigate("/login");
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      setError("Failed to reset password. Try again.");
    }
  };

  return (
    <>
      <div className="container-fluid vh-100 d-flex align-items-center">
        <div className="row w-100">
          {/* {left image section} */}
          <div className="col-md-6 d-flex  justify-content-center align-items-center p-3">
            <img
              src={inggagePng}
              alt="Platform"
              className="img-fluid rounded"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "80%",
                height: "auto",
                maxWidth: "800px",
                objectFit: "contain",
              }}
            />
          </div>

          {/* {  <!-- Right Section: Reset Password Form -->} */}
          <div className="col-md-6 d-flex justify-content-center align-items-center p-3">
            <div className="card p-4 w-100" style={{ maxWidth: "400px" }}>
              <h3 className="text-center mb-3">Reset Password</h3>
              {error && <div className="alert alert-danger">{error}</div>}

              {step === 1 && (
                <div>
                  <label className="form-label">User type:</label>
                  <select
                    className="form-select mb-3"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option value="Influencer">Influencer</option>
                    <option value="Brand">Brand</option>
                  </select>

                  <label className="form-label">Email Address:</label>
                  <input
                    type="email"
                    className="form-control mb-3"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                  />
                  <button
                    className="btn btn-primary w-100"
                    onClick={handleGetOtp}
                  >
                    Get OTP
                  </button>
                </div>
              )}

              {step === 2 && (
                <div>
                  <label className="form-label">Enter OTP:</label>
                  <input
                    type="text"
                    className="form-control mb-3"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Enter OTP"
                  />
                  <button
                    className="btn btn-success w-100"
                    onClick={handleVerifyOtp}
                  >
                    Verify OTP
                  </button>
                </div>
              )}

              {step === 3 && (
                <div>
                  <label className="form-label">New Password:</label>
                  <div className="input-group mb-3">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      value={newPassword}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter new password"
                    />
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? "Hide" : "Show"}
                    </button>
                  </div>
                  <button
                    className="btn btn-success w-100"
                    onClick={handleResetPassword}
                  >
                    Reset Password
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
