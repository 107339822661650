// File: UpdateCreator.jsx
import React, { useState } from "react";
import baseUrl from "../../api/universalApi";

const UpdateCreator = ({ creators }) => {
  // State for search input
  const [search, setSearch] = useState("");

  // State for modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCreator, setSelectedCreator] = useState(null);

  // Filter creators based on search input
  const filteredCreators = creators.filter((creator) =>
    creator.displayname.toLowerCase().includes(search.toLowerCase())
  );

  // Render Green or Red Tick
  const renderTick = (value) => (
    <span style={{ color: value ? "green" : "red" }}>
      {value ? "✔️" : "❌"}
    </span>
  );

  // Handle Edit Button Click
  const handleEdit = (creator) => {
    setSelectedCreator(creator);
    setIsModalOpen(true);
  };

  // Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting data for", selectedCreator);

    try {
      // API call to update creator data
      const response = await fetch(`${baseUrl}/admin/updateCreator`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({selectedCreator:selectedCreator}),
      });

      const data = await response.json();
      if (data.success) {
        alert("Data updated successfully");
        setIsModalOpen(false);
      } else {
        alert("Error updating data");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error updating data");
    }
  };

  return (
    <div>
      
      {/* Table */}
      <div style={{ height: "50vh", overflowY: "auto", border: "1px solid #ccc" }}>
        {/* Search Bar */}
      <input
        type="text"
        placeholder="Search by Name"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        style={{
          marginBottom: "10px",
          padding: "8px",
          width: "100%",
          borderRadius: "4px",
          border: "1px solid #ccc",
        }}
      />

        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr style={{ borderBottom: "1px solid #ddd" }}>
              <th style={{ padding: "8px" }}>S.No</th>
              <th style={{ padding: "8px" }}>Name</th>
              <th style={{ padding: "8px" }}>Media ID</th>
              <th style={{ padding: "8px" }}>Reel Link</th>
              <th style={{ padding: "8px" }}>Avg Views</th>
              <th style={{ padding: "8px" }}>ACPV</th>
              <th style={{ padding: "8px" }}>Edit</th>
            </tr>
          </thead>
          <tbody>
            {filteredCreators.map((creator, index) => (
              <tr key={creator.id || index} style={{ borderBottom: "1px solid #ddd" }}>
                <td style={{ padding: "8px" }}>{index + 1}</td>
                <td style={{ padding: "8px" }}>{creator.displayname}</td>
                <td style={{ padding: "8px" }}>{renderTick(creator.mediaID)}</td>
                <td style={{ padding: "8px" }}>{renderTick(creator.reelLink)}</td>
                <td style={{ padding: "8px" }}>{renderTick(creator.avgViews)}</td>
                <td style={{ padding: "8px" }}>{renderTick(creator.ACPV)}</td>
                <td style={{ padding: "8px" }}>
                  <button
                  className="btn btn-primary"
                    onClick={() => handleEdit(creator)}
                    style={{ padding: "5px 10px", cursor: "pointer" }}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "8px",
              width: "400px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h3>Edit Creator</h3>
            <form onSubmit={handleSubmit}>
              <div style={{ marginBottom: "10px" }}>
                <label>Media ID:</label>
                <input
                  type="text"
                  value={selectedCreator.mediaID || ""}
                  onChange={(e) =>
                    setSelectedCreator({ ...selectedCreator, mediaID: e.target.value })
                  }
                  style={{ width: "100%", padding: "8px", marginBottom: "10px" }}
                />
              </div>
              <div style={{ marginBottom: "10px" }}>
                <label>Reel Link:</label>
                <input
                  type="text"
                  value={selectedCreator.reelLink || ""}
                  onChange={(e) =>
                    setSelectedCreator({ ...selectedCreator, reelLink: e.target.value })
                  }
                  style={{ width: "100%", padding: "8px", marginBottom: "10px" }}
                />
              </div>
              <div style={{ marginBottom: "10px" }}>
                <label>Avg Views:</label>
                <input
                  type="text"
                  value={selectedCreator.avgViews || ""}
                  onChange={(e) =>
                    setSelectedCreator({ ...selectedCreator, avgViews: e.target.value })
                  }
                  style={{ width: "100%", padding: "8px", marginBottom: "10px" }}
                />
              </div>
              <div style={{ marginBottom: "10px" }}>
                <label>ACPV:</label>
                <input
                  type="text"
                  value={selectedCreator.ACPV || ""}
                  onChange={(e) =>
                    setSelectedCreator({ ...selectedCreator, ACPV: e.target.value })
                  }
                  style={{ width: "100%", padding: "8px", marginBottom: "10px" }}
                />
              </div>
              <div className=" flex gap-2">
              <button
              className="btn btn-primary"
                type="submit"
                style={{ padding: "8px 16px", cursor: "pointer" }}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setIsModalOpen(false)}
                style={{ padding: "8px 16px", cursor: "pointer", marginLeft: "10px" }}
              >
                Cancel
              </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateCreator;
