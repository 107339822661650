import React from "react";
import styles from "./Overview.module.css";
import CustomLineChart from './LineChart';
import CampaignPerformance from "./CampaignPerformance";


const Overview = () => {
  return (
    <>
      <div className={styles.overviewContainer}>
        <div className={styles.card}>
          <div className={styles.cardContent}>
            <p>Transaction amount till now</p>
            
            <h3 className={styles.redText}>₹45,231.89</h3>
            <p className={styles.subText}>+20.1% from last month</p>
          </div>
          <div className={styles.icon}>💵</div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardContent}>
            <p>CTR</p>
            <h3 className={styles.blackText}>+2350%</h3>
            <p className={styles.subText}>+180.1% from last month</p>
          </div>
          <div className={styles.icon}>🥰</div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardContent}>
            <p>Engagement Rate</p>
            <h3 className={styles.blackText}>+12%</h3>
            <p className={styles.subText}>+3% from last month</p>
          </div>
          <div className={styles.icon}>🗓️</div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardContent}>
            <p>CPM</p>
            <h3 className={styles.blackText}>+573</h3>
            <p className={styles.subText}>+201 since last host</p>
          </div>
          <div className={styles.icon}>🔂</div>
        </div>
      </div>
      <div className={styles.ChartNPerform}>
        <div className={styles.chartContainer}>{ <CustomLineChart />}</div>
        <div className={styles.performanceContainer}>
          <CampaignPerformance />
        </div>
      </div>
    </>
  );
};

export default Overview;
