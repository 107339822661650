import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./BrandAnalytics.module.css";
import { useDispatch, useSelector } from "react-redux";
import Overview from "./Overview/Overview";
import baseUrl from "../../api/universalApi";
import Requests from "./Requests/Requests";
import CampaignColl from "./CampaignCollection/CampaignColl";
import { useNavigate } from "react-router-dom";
import { saveCampaignData } from "../../actions/BrandActions";
import axios from "axios";
import { FaDownload } from "react-icons/fa";
import Analytics from "./Analytics/Analytics";

const BrandAnalytics = ({
  requestedCreator,
  influencerData,
  campaignData,
  campaignId,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams(); // Fetching 'id' from the route parameters
  // console.log("id",id)
  const navigate = useNavigate();
  const authData = useSelector((state) => state.auth.authData?.user);
  const [campaignData1, setCampaignData] = useState(null);
  // collect all influencer Data.
  //get blogs
  const [activeTab, setActiveTab] = useState("analytics");

  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        // Sending a simple GET request without JSON Content-Type
        const response = await axios.get(
          `${baseUrl}/campaign/getCampaignData/${id}`
        );
        // console.log("response",response)
        if (response.status === 200) {
          const data = response.data; // Axios automatically parses JSON
          //console.log("Fetched campaign data:", data);
          setCampaignData(data?.data); // Storing fetched data in state
          //console.log("Fetched campaign data:", data);
          dispatch(saveCampaignData(data));
          // console.log(data)
        } else {
          console.error("Failed to fetch campaign data");
        }
      } catch (error) {
        console.error("Error in API call:", error);
      }
    };

    if (id) {
      fetchCampaignData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignData]); // Dependency array includes 'id' so it re-runs if 'id' changes

  const renderContent = () => {
    switch (activeTab) {
      case "overview":
        return (
          <Overview
            campaignData1={campaignData1}
            influencerData={influencerData}
          />
        );
      case "requests":
        return <Requests requestedCreator={requestedCreator} />;
      case "collection":
        return <CampaignColl campaignData1={campaignData1} />;
      case "analytics":
        return <Analytics campaignData={campaignData1} />;
      default:
        return <Analytics campaignData={campaignData1} />;
    }
  };

  const handleCampaign = () => {
    navigate("/createcampaign");
  };

  return (
    <>
      <div className="p-2">
        <header className="flex flex-col p-2 sm:flex-row justify-center items-start sm:items-center sm:mb-2 justify-between bg-white rounded-md">
          <h1 className="text-2xl font-bold mb-2">
            Welcome {authData?.displayname},
          </h1>

          <div className="flex items-center gap-4 mb-2">
            {/* Create Campaign Button */}
            <button
              className="bg-[#ff006b] text-white px-4 py-2 rounded-full hover:bg-pink-600 transition"
              onClick={handleCampaign}
            >
              Create Campaign
            </button>

           

            {/* Download Button */}
            <button className="bg-gray-100 text-2xl text-gray-600 p-2 rounded-full hover:bg-gray-200 transition">
              <FaDownload />
            </button>
          </div>
        </header>

        <div className="flex items-center justify-start   rounded-lg mb-3 ">
          <div
            className="flex items-center justify-start px-3 py-1 pb-0 bg-gray-100 rounded space-x-6"
            style={{ fontFamily: "Gilroy-Medium" }}
          >
            <button
              className={`relative pb-1  font-medium ${
                activeTab === "analytics"
                  ? "text-pink-600 after:block after:h-0.5 after:w-full after:bg-pink-600"
                  : "text-gray-500 hover:text-gray-700"
              }`}
              onClick={() => setActiveTab("analytics")}
            >
              Analytics
            </button>

            <button
              className={`relative pb-1  font-medium ${
                activeTab === "overview"
                  ? "text-pink-600 after:block after:h-0.5 after:w-full after:bg-pink-600"
                  : "text-gray-500 hover:text-gray-700"
              }`}
              onClick={() => setActiveTab("overview")}
            >
              Overview
            </button>

            <button
              className={`relative pb-1  font-medium ${
                activeTab === "requests"
                  ? "text-pink-600 after:block after:h-0.5 after:w-full after:bg-pink-600"
                  : "text-gray-500 hover:text-gray-700"
              }`}
              onClick={() => setActiveTab("requests")}
            >
              Requests
            </button>

            <button
              className={`relative pb-1  font-medium ${
                activeTab === "collection"
                  ? "text-pink-600 after:block after:h-0.5 after:w-full after:bg-pink-600"
                  : "text-gray-500 hover:text-gray-700"
              }`}
              onClick={() => setActiveTab("collection")}
            >
              Collection
            </button>
          </div>
        </div>

        <div className={styles.contentContainer}>{renderContent()}</div>
      </div>
    </>
  );
};

export default BrandAnalytics;
