import React, { useEffect, useState } from "react";
import baseUrl from "../../../api/universalApi";
import styles from "./Analytics.module.css";

const Analytics = ({ campaignData }) => {
  // Define all hooks first
  console.log(campaignData);
  const [graphData, setGraphData] = useState([]);
  const [insights, setInsights] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filterMetric, setFilterMetric] = useState("views");
  const [creatorPerformance, setCreatorPerformance] = useState({});

  // Helper functions moved to top level
  const findPeakInInterval = (metrics) => {
    if (!metrics.length) return { count: 0, timestamp: null };
    return metrics.reduce((max, current) =>
      current.count > max.count ? current : max
    );
  };

  const getTimeInterval = (date) => {
    const hours = date.getHours();
    if (hours >= 5 && hours < 12) return "Morning (5 AM - 12 PM)";
    if (hours >= 12 && hours < 17) return "Afternoon (12 PM - 5 PM)";
    if (hours >= 17 && hours < 21) return "Evening (5 PM - 9 PM)";
    return "Night (9 PM - 5 AM)";
  };

  const findPeakInterval = (intervalPeaks, metric) => {
    let maxInterval = null;
    let maxCount = -1;

    Object.entries(intervalPeaks).forEach(([interval, metrics]) => {
      if (metrics[metric].count > maxCount) {
        maxCount = metrics[metric].count;
        maxInterval = interval;
      }
    });

    return {
      interval: maxInterval,
      count: maxCount,
      time: maxInterval
        ? new Date(
            intervalPeaks[maxInterval][metric].timestamp
          ).toLocaleString()
        : null,
    };
  };

  // Main analysis function
  const analyzeGraphData = (data) => {
    if (!data || data.length === 0) return null;

    // Sort data by timestamp
    const sortedData = [...data].sort(
      (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
    );

    // Group data by time intervals
    const timeIntervalData = sortedData.reduce((acc, entry) => {
      const date = new Date(entry.timestamp);
      const interval = getTimeInterval(date);

      if (!acc[interval]) {
        acc[interval] = {
          views: [],
          likes: [],
          comments: [],
          shares: [],
        };
      }

      acc[interval].views.push({
        count: entry.views,
        timestamp: entry.timestamp,
      });
      acc[interval].likes.push({
        count: entry.likes,
        timestamp: entry.timestamp,
      });
      acc[interval].comments.push({
        count: entry.comments,
        timestamp: entry.timestamp,
      });
      acc[interval].shares.push({
        count: entry.shares,
        timestamp: entry.timestamp,
      });

      return acc;
    }, {});

    // Analyze each interval to find peaks
    const intervalPeaks = {};
    Object.entries(timeIntervalData).forEach(([interval, metrics]) => {
      intervalPeaks[interval] = {
        views: findPeakInInterval(metrics.views),
        likes: findPeakInInterval(metrics.likes),
        comments: findPeakInInterval(metrics.comments),
        shares: findPeakInInterval(metrics.shares),
      };
    });

    return {
      peaks: {
        views: findPeakInterval(intervalPeaks, "views"),
        likes: findPeakInterval(intervalPeaks, "likes"),
        comments: findPeakInterval(intervalPeaks, "comments"),
        shares: findPeakInterval(intervalPeaks, "shares"),
      },
    };
  };

  // Calculate creator performance
  const calculateCreatorPerformance = (creators) => {
    const performances = {};

    creators.forEach((creator) => {
      const currentViews = creator.currentReelData?.[0]?.views || 0;
      const avgViews = creator.avgViews || 0;
      if (avgViews > 0) {
        const performance = (Math.abs(currentViews - avgViews) / avgViews) * 100;
        performances[creator._id] = {
          percentage: performance,
          creatorName: creator.displayname,
        };
      }
    });

    return performances;
  };

  useEffect(() => {
    const fetchAllCreatorsData = async () => {
      if (!campaignData || !campaignData.length || !campaignData[0].creators) {
        setLoading(false);
        return;
      }

      const campaign = campaignData[0];

      try {
        const promises = campaign.creators.map((creator) =>
          fetch(`${baseUrl}/campaign/campaignDataRefresh`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ influxCreatorName: creator.displayname }),
          }).then((res) => res.json())
        );

        const results = await Promise.all(promises);
        const allGraphData = results.flatMap(
          (result) => result.graphData || []
        );
        setGraphData(allGraphData);

        if (allGraphData.length > 0) {
          const newInsights = analyzeGraphData(allGraphData);
          setInsights(newInsights);
        }

        const performanceData = calculateCreatorPerformance(campaign.creators);
        setCreatorPerformance(performanceData);
      } catch (error) {
        console.error("Error fetching graph data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllCreatorsData();
  }, [campaignData]);

  // Return early if no data
  if (!campaignData || campaignData.length === 0) {
    return <div>No campaign data available</div>;
  }

  if (loading) {
    return <div>Loading campaign data...</div>;
  }

  const campaign = campaignData[0];

  const totalMetrics = campaign.creators.reduce(
    (acc, creator) => {
      const currentReel = creator.currentReelData?.[0] || {};
      return {
        likes: acc.likes + (currentReel.likes || 0),
        views: acc.views + (currentReel.views || 0),
        comments: acc.comments + (currentReel.comments || 0),
        cost: acc.cost + (creator.earnedAmount || 0),
      };
    },
    { likes: 0, views: 0, comments: 0, cost: 0 }
  );

  const totalACPV = (totalMetrics.cost / totalMetrics.views || 0).toFixed(2);

  const engagementRate =
    (
      ((totalMetrics.likes + totalMetrics.comments) / totalMetrics.views) *
      100
    ).toFixed(2) || 0;

    const getTopCreators = (metric) => {
      let creators = [...campaign.creators].map((creator) => ({
        ...creator,
        metrics: creator.currentReelData?.[0] || {
          views: 0,
          likes: 0,
          comments: 0,
        },
      }));
  
      if (metric === "performance") {
        creators = creators.map((creator) => ({
          ...creator,
          performance: creatorPerformance[creator._id],
        }));
  
        creators.sort((a, b) => {
          if (!a.performance || a.performance.percentage === 0) return 1;
          if (!b.performance || b.performance.percentage === 0) return -1;
          return b.performance.percentage - a.performance.percentage;
        });
  
        return creators.slice(0, 2);
      } else {
        return creators.sort((a, b) => (b.metrics[metric] || 0) - (a.metrics[metric] || 0)).slice(0, 2);
      }
    };

  const topCreators = getTopCreators(filterMetric);

  return (
    <div className="w-full p-4" style={{ fontFamily: "Gilroy-Medium" }}>
      {/* Header */}
      <div className="bg-white p-4 rounded-lg border-2 shadow-md mb-8">
        <h1 className="text-2xl font-semibold text-gray-800 mb-6">
          Campaign Analytics
        </h1>
        <div className="border-l-4 border-blue-500 pl-4">
          <h2 className="text-xl font-semibold text-blue-600 mb-2">
            {campaign.campaignName || "Campaign Name Not Available"}
          </h2>
          <p className="text-gray-600">
            {campaign.campaignDescription || "No description available"}
          </p>
          <div className="flex flex-col md:flex-row gap-4 mt-4 text-gray-500">
            <span>
              Start: {new Date(campaign.startDate).toLocaleDateString()}
            </span>
          </div>
          <p className="inline-block bg-blue-100 text-blue-600 px-4 py-1 rounded-full mt-2">
            Platform: {campaign.platform || "N/A"}
          </p>
        </div>
      </div>

      {/* Metrics Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        <div className="bg-white p-6 border-2 rounded-lg shadow hover:shadow-lg transition-transform transform hover:-translate-y-2">
          <h3 className="text-sm text-gray-600 mb-2">Total Cost</h3>
          <p className="text-2xl font-bold text-blue-600">
            ₹{totalMetrics.cost.toLocaleString()}
          </p>
        </div>
        <div className="bg-white p-6 border-2 rounded-lg shadow hover:shadow-lg transition-transform transform hover:-translate-y-2">
          <h3 className="text-sm text-gray-600 mb-2">Engagement Rate</h3>
          <p className="text-2xl font-bold text-blue-600">{engagementRate}%</p>
        </div>
        <div className="bg-white p-6 border-2 rounded-lg shadow hover:shadow-lg transition-transform transform hover:-translate-y-2">
          <h3 className="text-sm text-gray-600 mb-2">Total Views</h3>
          <p className="text-2xl font-bold text-blue-600">
            {totalMetrics.views.toLocaleString()}
          </p>
        </div>
        <div className="bg-white p-6 border-2 rounded-lg shadow hover:shadow-lg transition-transform transform hover:-translate-y-2">
          <h3 className="text-sm text-gray-600 mb-2">Total Likes</h3>
          <p className="text-2xl font-bold text-blue-600">
            {totalMetrics.likes.toLocaleString()}
          </p>
        </div>
        <div className="bg-white p-6 border-2 rounded-lg shadow hover:shadow-lg transition-transform transform hover:-translate-y-2">
          <h3 className="text-sm text-gray-600 mb-2">Total Comments</h3>
          <p className="text-2xl font-bold text-blue-600">
            {totalMetrics.comments.toLocaleString()}
          </p>
        </div>
        <div className="bg-white p-6 border-2 rounded-lg shadow hover:shadow-lg transition-transform transform hover:-translate-y-2">
          <h3 className="text-sm text-gray-600 mb-2">Total ACPV</h3>
          <p className="text-2xl font-bold text-blue-600">{totalACPV}</p>
        </div>
      </div>

      <div className={styles.topCreators}>
        <div className={styles.topCreatorsHeader}>
          <h2>Top Performing Creators</h2>
          <div className={styles.filterControls}>
            <select
              value={filterMetric}
              onChange={(e) => setFilterMetric(e.target.value)}
              className={styles.filterSelect}
            >
              <option value="views">By Views</option>
              <option value="likes">By Likes</option>
              <option value="comments">By Comments</option>
              <option value="performance">By Performance</option>
            </select>
          </div>
        </div>
        <div className={styles.creatorsGrid}>
          {topCreators.map((creator, index) => (
            <div
              key={creator._id}
              className="bg-gray-100 p-6 border-2 rounded-lg shadow"
            >
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h3 className="text-lg font-semibold text-gray-800">
                    {creator.displayname}
                  </h3>
                  <p className="text-xs text-gray-600">{creator.email}</p>
                </div>
                <span className="text-xs bg-blue-100 text-blue-600 px-3 py-1 rounded-full">
                  Rank #{index + 1}
                </span>
              </div>

              <div className={styles.creatorMetrics}>
                <div
                  className={
                    filterMetric === "views" ? styles.highlightedMetric : ""
                  }
                >
                  <p>Views</p>
                  <span>{creator.metrics.views?.toLocaleString() || 0}</span>
                </div>
                <div
                  className={
                    filterMetric === "likes" ? styles.highlightedMetric : ""
                  }
                >
                  <p>Likes</p>
                  <span>{creator.metrics.likes?.toLocaleString() || 0}</span>
                </div>
                <div
                  className={
                    filterMetric === "comments" ? styles.highlightedMetric : ""
                  }
                >
                  <p>Comments</p>
                  <span>{creator.metrics.comments?.toLocaleString() || 0}</span>
                </div>
                <div
                  className={
                    filterMetric === "performance" ? styles.highlightedMetric : ""
                  }
                >
                  <p>Performance</p>
                  <span>
                    {creatorPerformance[creator._id]?.percentage.toFixed(2)}%
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Performance Insights */}
      {insights && (
        <div className="mb-8">
          <h2 className="text-lg font-semibold text-gray-800 mb-4">
            Performance Insights
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div className="bg-white p-6 border-2 rounded-lg shadow">
              <h3 className="text-sm font-medium text-gray-600 mb-4">
                Peak Performance Times
              </h3>
              <div className="space-y-4">
                <div>
                  <p className="text-sm text-gray-600">Most Views</p>
                  <p className="text-lg font-bold text-gray-800">
                    {insights.peaks.views.count.toLocaleString()}
                  </p>
                  <small className="text-xs text-gray-500">
                    during {insights.peaks.views.interval}
                  </small>
                </div>
                {/* Repeat for other metrics */}
              </div>
            </div>

          </div>
        </div>
      )}
    </div>
  );
};

export default Analytics;
