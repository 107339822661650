// File: CreatorList.jsx
import React, { useState } from "react";
import baseUrl from "../../api/universalApi";

const CreatorList = ({ creators }) => {
  // State for search input
  const [search, setSearch] = useState("");

  // Filter creators based on search input
  const filteredCreators = creators.filter((creator) =>
    creator.displayname.toLowerCase().includes(search.toLowerCase())
  );

  // Render Green or Red Tick
  const renderTick = (value) => (
    <span style={{ color: value ? "green" : "red" }}>
      {value ? "✔️" : "❌"}
    </span>
  );

  // Handle Refresh Button Click
  const handleRefresh = async (creatorId, instaId, accessToken) => {
    console.log(`Refreshing data for creator ID: ${creatorId}`);
    try {
      const response = await fetch(`${baseUrl}/api/updateCreatorInsta`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ creatorId, instaId, accessToken }),
      });
      const data = await response.json();
      console.log(data);
      if (data.success) {
        alert("Data saved successfully");
      } else {
        alert("Error in saving data");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error in saving data");
    }
  };

  return (
    <div style={{ height: "50vh", overflowY: "auto", border: "1px solid #ccc", marginBottom:"20px"  }}>
      {/* Search Bar */}
      <input
        type="text"
        placeholder="Search by Name"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        style={{
          marginBottom: "10px",
          padding: "8px",
          width: "100%",
          borderRadius: "4px",
          border: "1px solid #ccc",
         
        }}
      />

      {/* Table */}
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr style={{ borderBottom: "1px solid #ddd" }}>
            <th style={{ padding: "8px" }}>S.No</th>
            <th style={{ padding: "8px" }}>Display Name</th>
            <th style={{ padding: "8px" }}>Insta ID</th>
            <th style={{ padding: "8px" }}>Access Token</th>
            <th style={{ padding: "8px" }}>Refresh Data</th>
          </tr>
        </thead>
        <tbody>
          {filteredCreators.map((creator, index) => (
            <tr
              key={creator._id || index}
              style={{ borderBottom: "1px solid #ddd" }}
            >
              <td style={{ padding: "8px" }}>{index + 1}</td>
              <td style={{ padding: "8px" }}>
                <img
                  src={creator.profile_image}
                  alt="ProfileImg"
                  style={{
                    height: "20px",
                    width: "20px",
                    borderRadius: "50%",
                    marginRight: "12px",
                  }}
                ></img>

                {creator.displayname}
              </td>
              <td style={{ padding: "8px" }}>{renderTick(creator.instaId)}</td>
              <td style={{ padding: "8px" }}>
                {renderTick(creator.longLivedAccess)}
              </td>
              <td style={{ padding: "8px", textAlign: "center" }}>
                {creator.instaId && creator.longLivedAccess ? (
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      handleRefresh(
                        creator._id,
                        creator.instaId,
                        creator.longLivedAccess
                      )
                    }
                    style={{ padding: "5px 10px", cursor: "pointer" }}
                  >
                    Refresh
                  </button>
                ) : (
                  "N/A"
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CreatorList;
